import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/businessType';
import {
  businessTypeForRegion as businessTypeForRegionRecoil,
  businessTypeForRegionComparison as businessTypeForRegionComparisonRecoil,
} from 'state/global/businessType';

const useFetchBusinessTypeForRegion = (id, regionId) => {
  const setBusinessTypeForRegion = useSetRecoilState(businessTypeForRegionRecoil);
  const setBusinessTypeForRegionComparison = useSetRecoilState(businessTypeForRegionComparisonRecoil);

  return query.useQueryWrapper(
    'businessTypeForRegion',
    async () => {
      const businessTypeForRegion = await actions.fetchBusinessTypeForRegion(id, regionId);
      setBusinessTypeForRegion(businessTypeForRegion);
      setBusinessTypeForRegionComparison(businessTypeForRegion);
      return businessTypeForRegion;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchBusinessTypeForRegion;
