/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import formatDate from 'utils/KubenAssessments/formatDate';

import useDeleteAssessment from 'hooks/api/assessmentResult/useDeleteAssessment';
import useFetchAllIncompleteAssessments from 'hooks/api/assessmentResult/useFetchAllIncompleteAssessments';
import useFetchAllHistoryAssessment from 'hooks/api/assessmentResult/useFetchAllHistoryAssessment';
import useFetchCompletedAssessment from 'hooks/api/assessmentResult/useFetchCompletedAssessment';
import useFetchTemplateAssessment from 'hooks/api/assessment/useFetchTemplateAssessment';
import useSaveAssessmentEndDate from 'hooks/api/assessmentResult/useSaveAssessmentEndDate';
import useFetchAllHistoryAssessmentForManager from 'hooks/api/assessmentResult/useFetchAllHistoryAssessmentForManager';
import useCreateReassessmentFromCompletedAssessment from 'hooks/api/assessmentResult/useCreateReassessmentFromCompletedAssessment';

import { currentUser } from 'state/global/currentUser';
import { chosenTemplate as chosenTemplateRecoil } from 'state/global/templates';
import { chosenRegion as chosenRegionRecoil } from 'state/KubenAssessments/choosingTemplate/region';
import { chosenAssessmentAction as chosenAssessmentActionRecoil } from 'state/KubenAssessments/chosenAssessmentAction/chosenAssessmentAction';
import { answersAssessment } from 'state/KubenAssessments/assessments/answersAssessment';
import { selectedPerson as selectedPersonRecoil } from 'state/KubenAssessments/saved/savedSelectedPerson';
import { latestAnsweredAssessment } from 'state/KubenAssessments/questions/latestAnsweredAssessment';
import { allIncompleteAssessments as allIncompleteAssessmentsRecoil } from 'state/KubenAssessments/assessments/allIncompleteAssessments';
import { allAssessmentHistory as allAssessmentHistoryRecoil } from 'state/KubenAssessments/assessmentHistory/allAssessmentHistory';
import { currentQuestionState } from 'state/KubenAssessments/questions/currentQuestions';
import { allAssessmentHistoryForManager as allAssessmentHistoryForManagerRecoil } from 'state/KubenAssessments/assessmentHistory/allAssessmentHistoryForManager';

import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';
import UIWarningModal from 'components/global/UIModals/UIWarningModal';

import AssessmentHistoryDateModal from 'components/KubenAssessments/AssessmentHistoryPage/AssessmentHistoryDateModal';
import { HiOutlineClock, HiOutlineDocumentAdd, HiOutlineSearch } from 'react-icons/hi';
import AssessmentDashboardLinkCard from 'components/KubenAssessments/AssessmentDashboardPage/AssessmentDashboardLinkCard';
import AssessmentDashboardItemMenu from 'components/KubenAssessments/AssessmentDashboardPage/AssessmentDashboardItemMenu';
import UIAssessmentTable from 'components/KubenAssessments/UIAssessments/UIAssessmentTable';
import UINameHolder from 'components/KubenAssessments/UIAssessments/UINameHolder';

const AssessmentsDashboardPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useRecoilValue(currentUser);

  const onSuccess = () => {
    history.push('/assessments/questions');
  };

  // warning modal mechanism
  const [openWarningModal, setOpenWarningModal] = useState();
  const [elementToDelete, setElementToDelete] = useState();
  const [elementTypeToDelete, setElementTypeToDelete] = useState();

  // date modal - set endDate properties
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [assessmentData, setAssessmentData] = useState({});
  const [assessmentEndDateData, setAssessmentEndDateData] = useState('');

  // lists mechanism
  const {
    isLoading: isAllIncompleteAssessmentsLoading,
    isError: isAllIncompleteAssessmentsError,
    mutate: mutateAllIncompleteAssessments,
  } = useFetchAllIncompleteAssessments(1, 5);
  const {
    isLoading: isAllHistoryAssessmentsLoading,
    isError: isAllHistoryAssessmentsError,
    mutate: mutateFetchAllHistoryAssessments,
    data: allHistoryAssessmentData,
  } = useFetchAllHistoryAssessment(1, 5);
  const {
    isLoading: isAllHistoryAssessmentsForManagerLoading,
    isError: isAllHistoryAssessmentsForManagerError,
    mutate: mutateFetchAllHistoryAssessmentsForManager,
  } = useFetchAllHistoryAssessmentForManager(1, 5);
  const {
    isLoading: isDeletingAssessmentLoading,
    isError: isDeletingAssessmentError,
    isSuccess: isDeleteAssessmentSuccess,
    mutate: deleteIncompleteAssessment,
  } = useDeleteAssessment(elementToDelete, elementTypeToDelete);
  const [activeListIndex, setActiveListIndex] = useState(0);


  const allIncompleteAssessments = useRecoilValue(allIncompleteAssessmentsRecoil);
  const allHistoryAssessmentsForManager = useRecoilValue(allAssessmentHistoryForManagerRecoil);
  const allHistoryAssessment = useRecoilValue(allAssessmentHistoryRecoil);

  const [assessmentsDataForTab, setAssessmentsDataForTab] = useState([]);

  // setting data for continue assessment
  const [chosenRegion, setChosenRegion] = useRecoilState(chosenRegionRecoil);
  const [chosenTemplate, setChosenTemplate] = useRecoilState(chosenTemplateRecoil);
  const setChosenAssesmentAction = useSetRecoilState(chosenAssessmentActionRecoil);
  const setAnswersAssessment = useSetRecoilState(answersAssessment);
  const setSelectedPerson = useSetRecoilState(selectedPersonRecoil);

  // resetting all data while entering page
  const resetChosenRegion = useResetRecoilState(chosenRegionRecoil);
  const resetChosenTemplate = useResetRecoilState(chosenTemplateRecoil);
  const resetChosenAssesmentAction = useResetRecoilState(chosenAssessmentActionRecoil);
  const resetAnswersAssessment = useResetRecoilState(answersAssessment);
  const resetSelectedPerson = useResetRecoilState(selectedPersonRecoil);
  const resetCurrentQuestion = useResetRecoilState(currentQuestionState);
  const resetLatestAnsweredAssessment = useResetRecoilState(latestAnsweredAssessment);

  const stateColorMapping = {
    ApprovalDenied: 'background-error',
    Completed: 'background-success',
    ConfigureServices: 'background-warning',
    Incomplete: 'background-info',
    PendingApproval: 'background-caution', 
  };

  const lists = [
    {
      title: 'Mina påbörjade',
      description: 'Mina påbörjade bedömningar',
      data: allIncompleteAssessments?.results,
      urlLink: '/assessments/assessment-history/initial',
      type: 'started',
      isError: isAllIncompleteAssessmentsError,
    },
    {
      title: 'Mina avslutade',
      description: 'Mina avslutade bedömningar',
      data: assessmentsDataForTab,
      urlLink: '/assessments/assessment-history/completed',
      type: 'mineFinished',
      data: allHistoryAssessment?.results,
      isError: isAllHistoryAssessmentsError,
    },
    {
      title: 'Alla avslutade',
      description: 'Alla avslutade bedömningar',
      data: allHistoryAssessmentsForManager?.results,
      urlLink: '/assessments/assessment-history/all-completed',
      type: 'allFinished',
      isError: isAllHistoryAssessmentsForManagerError,
    },
  ];

  // State to manage which list is currently displayed

  // dropdown options mechanism
  const {
    isLoading: isAssessmentResultLoading,
    isError: isAssessmentResultError,
    mutate: mutateAssessmentResult,
  } = useFetchCompletedAssessment({ onSuccess });
  const {
    isLoading: isCreateReassessmentFromCompletedAssessmentLoading,
    isError: isCreateReassessmentFromCompletedAssessmentError,
    mutate: mutateCreateReassessmentFromCompletedAssessment,
  } = useCreateReassessmentFromCompletedAssessment({ onSuccess });
  const {
    isLoading: isTemplateAssessmentLoading,
    isError: isTemplateAssessmentError,
    mutate: mutateTemplateAssessment,
  } = useFetchTemplateAssessment(chosenTemplate?.id, chosenRegion?.id);
  const {
    isLoading: isSetEndDateLoading,
    isError: isSetEndDateError,
    mutate: mutateSetEndDate,
    isSuccess: isSetEndDateSuccess,
  } = useSaveAssessmentEndDate(assessmentEndDateData);
  const [isRedirectLoading, setIsRedirectLoading] = useState(false);

  const handleListSelect = (index) => {
    const list = lists[index];

    switch(list.type) {
      case 'started':
        break;
      case 'mineFinished':
        break;
      case 'allFinished':
        break;
    }

    setActiveListIndex(index);
  };

  // reset all data while entering page
  useEffect(() => {
    resetChosenRegion();
    resetChosenTemplate();
    resetChosenAssesmentAction();
    resetAnswersAssessment();
    resetSelectedPerson();
    resetCurrentQuestion();
    resetLatestAnsweredAssessment();
    setIsRedirectLoading(false);
  }, []);

  // fetch completed and initial assessments
  useEffect(() => {
    mutateAllIncompleteAssessments();
    mutateFetchAllHistoryAssessments();
    mutateFetchAllHistoryAssessmentsForManager();
  }, []);

  // set data for table at inital
  useEffect(() => {
    if (allHistoryAssessmentData != null) {
      setAssessmentsDataForTab(allHistoryAssessment?.results);
    }
  }, [allHistoryAssessmentData]);

  // fetch completed and initial assessments
  useEffect(() => {
    if (isSetEndDateSuccess)  {
      mutateFetchAllHistoryAssessments();
    }
  }, [isSetEndDateSuccess]);

  // refetch assessments after deleting one
  useEffect(() => {
    if (isDeleteAssessmentSuccess) {
      mutateAllIncompleteAssessments();
      mutateFetchAllHistoryAssessments();
    }
  }, [isDeleteAssessmentSuccess]);

  // dropdown options actions
  useEffect(() => {
    if (chosenRegion?.id && chosenTemplate?.id) {
      mutateTemplateAssessment();
    }
    if (assessmentEndDateData) {
      mutateSetEndDate();
    }
  }, [chosenRegion, chosenTemplate, assessmentEndDateData]);

  // fuction for endDate modal
  const handleDateModalClick = (action, assessmentId, assessmentEndDate, reasonEndDay) => {
    if (action === 'closeModal') setIsDateModalOpen(false);
    if (action === 'setEndDate') {
      setAssessmentEndDateData({
        id: assessmentId,
        date: assessmentEndDate !== null ? formatDate(assessmentEndDate, 'yyyy-MM-dd') : null,
        reason: reasonEndDay,
      });
      setIsDateModalOpen(false);
    }
  };

  const onDeleteElement = () => {
    deleteIncompleteAssessment();
    setOpenWarningModal(false);
  };

  const handleClick = (action, element) => {
    const id = element?.id;
    switch (action) {
      case 'deleteIncomplete':
      case 'deleteResult':
        setOpenWarningModal(true);
        setElementToDelete(id);
        setElementTypeToDelete(action);
        break;
      case 'goToPerson':
        setSelectedPerson(element.forPerson);
        history.push('/assessments/person/' + element.forPerson?.id);
        break;
      case 'continue':
        setChosenAssesmentAction('existingAssessment');
        setIsRedirectLoading(true);
        setChosenRegion(element.region);
        setChosenTemplate(element.assessment);
        setAnswersAssessment(element);
        setSelectedPerson(element.forPerson != null && element.forPerson);
        setTimeout(() => {
          history.push('/assessments/questions');
        }, 200);
        return;
      case 'reassess':
        mutateCreateReassessmentFromCompletedAssessment({ id });
        setSelectedPerson(element.forPerson != null && element.forPerson);
        return;
      case 'changeAnswers':
        mutateAssessmentResult({ id });
        setSelectedPerson(element.forPerson != null && element.forPerson);
        return;
      case 'showSummary':
        setIsRedirectLoading(true);
        // save all necessary data for summary page
        setChosenAssesmentAction('summaryReadonly');
        setAnswersAssessment(element);
        setChosenRegion(element?.region);
        setChosenTemplate(element?.assessment);
        setTimeout(() => {
          history.push('/assessments/questions-summary');
        }, 500);
        return;
      case 'showServices':
        history.push(`/assessments/services/${element?.id}`);
        return;
      case 'changeStopDate':
        setAssessmentData(element);
        setIsDateModalOpen(true);
        return;
    }
  };

  const isLoading =  (
    isAllIncompleteAssessmentsLoading ||
    isCreateReassessmentFromCompletedAssessmentLoading ||
    isDeletingAssessmentLoading ||
    isSetEndDateLoading ||
    isRedirectLoading ||
    isAllHistoryAssessmentsLoading ||
    isAssessmentResultLoading ||
    isTemplateAssessmentLoading ||
    isAllHistoryAssessmentsForManagerLoading ||
    assessmentsDataForTab === undefined
  );

  if (
    isDeletingAssessmentError ||
    isSetEndDateError ||
    isAssessmentResultError ||
    isCreateReassessmentFromCompletedAssessmentError ||
    isTemplateAssessmentError ||
    isAllHistoryAssessmentsForManagerError
  ) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <UIStaticComponentsWrapper>
      <UIWarningModal
        isOpen={openWarningModal}
        setOpen={setOpenWarningModal}
        onClick={onDeleteElement}
        modalTitle={t('UIButtons.edit')}
        modalMessage={t('UIModals.editModalMessage')}
        confirmButtonName={t('UIButtons.edit')}
      />
      <div className="flex max-w-[1640px] mx-auto assesment-wrapper h-full
      h-full w-full mx-auto assessmentPage text-left my-auto">
        <div className="flex flex-col w-full h-full startsidaTable space-y-4">
          <h1
            className='text-primary text-2xl font-medium'
          >Bedömningar</h1>
          <div className='w-full flex space-x-4'>
            <AssessmentDashboardLinkCard
              link="/assessments/choose-person"
              icon={<HiOutlineDocumentAdd />}
              title={t('AssessmentDashboardPage.links.assessments')}
              description='Börja en ny bedömning utifrån val av mall'
              isDisabled={!user?.hasAccessToPersonAssessment || isLoading}
            />
            <AssessmentDashboardLinkCard
              link="/assessments/choose-template"
              icon={<HiOutlineClock />}
              title={t('AssessmentDashboardPage.links.timming')}
              description='Börja en ny bedömning utifrån val av mall'
              isDisabled={!user?.hasAccessToUserAssessment || isLoading}
            />
            <AssessmentDashboardLinkCard
              link="/assessments/search"
              icon={<HiOutlineSearch />}
              title={t('AssessmentDashboardPage.links.search')}
              description='Sök och hantera brukare i systemet'
              isDisabled={!user?.hasAccessToSearch || isLoading}
            />
          </div>
          <div className="w-full h-full">
            <UIAssessmentTable
              title={
                <div
                  className='space-x-2 border border-tertiary rounded-lg p-1 w-fit'
                >
                    {lists.map((list, index) => (
                    <button
                        key={index}
                        onClick={() => handleListSelect(index)}
                        className={`px-4 py-2 rounded-lg text-md
                          ${activeListIndex === index ? 'background-primary text-white' : 'choice-card'}`}
                      >
                        <p className='text-base'>
                          {list.title}
                        </p>
                      </button>
                  ))}
                  </div>
              }
              isLoading={isLoading}
              url={lists[activeListIndex].urlLink}
              titles={['id', 'mall', 'enhet', 'status', 'datum', 'mer']}
              data={lists[activeListIndex].data?.map((row, idx) =>
                [
                  (row.forPerson ? 
                    <div key={idx} className="absolute inset-0">
                      <UINameHolder
                        id={row.forPerson.id}
                        fullName={row.forPerson.name}
                        socialSecurityNumber={row.forPerson.socialSecurityNumber}
                        gender={row.forPerson.gender}
                        reportId={row.forPerson.reportId}
                        isDashboardStyling={true}
                      />
                  </div> : row.forUser?.name),
                  row.assessment?.name,
                  row.assessment?.unit?.name,
                  row.state?.value ? (
                    <div className="flex items-center space-x-2">
                      <div
                        className={`h-1.5 w-1.5 rounded-full ${stateColorMapping[row.state.id]}`}
                      />
                      <span>{row.state.value}</span>
                    </div>
                  ) : 
                    null
                  ,
                  row.startDate ? formatDate(row.startDate) : null,
                  <AssessmentDashboardItemMenu
                    key={idx}
                    type={lists[activeListIndex].type}
                    alignBottom={idx > (lists[activeListIndex].data?.length / 2)}
                    element={row}
                    handleClick={handleClick}
                  />
                ]
              )
              }
            />
          </div>
        </div>
        {isDateModalOpen && (
          <AssessmentHistoryDateModal
            isOpen={isDateModalOpen}
            assessmentData={assessmentData}
            handleClick={(action, id, date, reason) => handleDateModalClick(action, id, date, reason)}
          />
        )}
      </div>
    </UIStaticComponentsWrapper>
  );
};

export default AssessmentsDashboardPage;
