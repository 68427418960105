import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { BiExpand } from 'react-icons/bi';
import { AiFillCloseCircle } from 'react-icons/ai';
import getGraphDefinitionIcon from 'utils/KubenDashboard/getGraphDefinitionIcon';
import formatNumber from 'utils/KubenDashboard/formatNumber';
import useClickOutsideCallback from 'utils/global/clickOutsideCallback';
import UISpinner from 'components/global/UICommon/UISpinner';
import PersonChart from './PersonChart';

const PersonChartWrapper = ({ chartId, graphDefinition, hasAccess, data }) => {
  const ref = useRef();
  const [isExpanded, setExpanded] = useState(false);

  const handleClose = () => {
    if (!isExpanded) {
      return;
    }

    setExpanded(false);
  };

  const toggleExpanded = () => {
    if (!hasAccess) {
      return;
    }

    setExpanded(!isExpanded);
  };

  useClickOutsideCallback(ref, handleClose);

  return (
    <div
      ref={ref}
      className={`flex flex-col background rounded-xl px-3 py-2 w-full h-full
        group row-span-3 3xl:row-span-4 
        ${isExpanded ? 'z-10 absolute inset-0' : 'border border-tertiary'}
        `}
      title={hasAccess ? graphDefinition.title : 'Du saknar behörigheter'}
    >
      <div className="relative h-full w-full flex flex-col">
        {
          <button
            onClick={isExpanded ? handleClose : toggleExpanded}
            className="absolute top-0 right-0 hover:opacity-50 text-xl"
          >
            {hasAccess &&
              (isExpanded ? <AiFillCloseCircle /> : <BiExpand className="opacity-0 group-hover:opacity-100" />)}
          </button>
        }
        {!data ? (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <UISpinner theme="fast" />
          </div>
        ) : (
          <>
            <div className="flex justify-between w-full h-fit">
              <div className="flex items-center h-fit space-x-2 w-full">
                <div className="border border-tertiary rounded p-0.5 h-7 w-7">{getGraphDefinitionIcon(chartId)}</div>
                <div className="preview-text text-md font-medium flex select-text">{graphDefinition.title}</div>
              </div>
            </div>
            <div
              className={`flex w-full h-full justify-between  relative
              ${isExpanded ? 'flex-col' : 'flex-row'} ${!hasAccess ? 'blur-[5px]' : ''} `}
            >
              {!isExpanded && (
                <div className="flex items-center">
                  <div
                    className="whitespace-nowrap select-text text-left preview-text
                 text-2xl 3xl:text-3xl font-medium"
                  >
                    {formatNumber(data.highlightedValue)}
                    <span className="text-base 3xl:text-lg font-medium">{data.units}</span>
                  </div>
                </div>
              )}
              <div className="relative flex-grow">
                <PersonChart
                  chartData={data}
                  isExpanded={isExpanded}
                  hasAccess={hasAccess}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

PersonChartWrapper.propTypes = {
  chartId: PropTypes.string.isRequired,
  hasAccess: PropTypes.bool.isRequired,
  graphDefinition: PropTypes.any,
  data: PropTypes.object,
};

export default PersonChartWrapper;
