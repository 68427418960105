import { React } from 'react';
import propTypes from 'prop-types';

import UIFoldableCard from 'components/KubenAdmin/UIAdmin/UIFoldableCard';
import UIButtonDropdownGroup from 'components/global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from 'components/global/UIButtons/UIItemDropdown';
import UIButtonSubmit from 'components/global/UIButtons/UIButtonSubmit';
import UIInputWrapper from '../UIAdmin/UIInputWrapper';
import UIInput from 'components/global/UICommon/UIInput';

const CapitalBufferVersionsForm = ({
  setElement,
  referenceVersions,
  element,
  onEditVersion,
  isReadonly,
  disabled,
  onOpenAddNewVersionModal,
  onChangeVersion,
}) => {
  return (
    <UIFoldableCard
      title="Buffertkrav"
      defaultOpen={true}
    >
      <div className="flex flex-col justify-center items-end space-y-4">
        <div className="flex justify-end space-x-6">
          <UIButtonSubmit
            name="Redigera befintlig version"
            variant="bg-app-assessment"
            onSubmit={onEditVersion}
            assessments={true}
            isIcon={false}
            fixedWidth={false}
            enabled={disabled}
          />
          <UIButtonSubmit
            name="Skapa ny version"
            variant="bg-app-assessment"
            onSubmit={onOpenAddNewVersionModal}
            assessments={true}
            isIcon={false}
            fixedWidth={false}
            enabled={disabled}
          />
        </div>
        {referenceVersions.length > 1 && (
          <div className="flex items-center text-sm">
            <p className="font-semibold mr-3">Historik</p>
            <UIButtonDropdownGroup
              value={
                (element.startDate != null ? element.startDate : 'Början') +
                (element.endDate != null ? ` - ${element.endDate}` : ' (Nuvarande period)')
              }
              width="w-80"
              absolute="absolute"
              zIndex={'z-30'}
              translate={false}
              style="white"
            >
              {referenceVersions.map((version, index) => (
                <UIItemDropdown
                  value={version.startDate}
                  displayValue={
                    (version.startDate != null ? version.startDate : 'Början') +
                    (version.endDate != null ? ` - ${version.endDate}` : ' (Nuvarande period)')
                  }
                  onChange={() => onChangeVersion(version.startDate)}
                  key={index}
                />
              ))}
            </UIButtonDropdownGroup>
          </div>
        )}
      </div>
      <UIInputWrapper
        key={element?.startDate}
        title="BUFFERTKRAV"
      >
        <UIInput
          id={`capitalBuffer-${element?.startDate}`}
          name={`capitalBuffer-${element?.startDate}`}
          onChange={(e) => setElement({ ...element, capitalBuffer: Number(e.target.value) })}
          value={element?.capitalBuffer}
          type="number"
          disabled={isReadonly}
        />
      </UIInputWrapper>
    </UIFoldableCard>
  );
};

CapitalBufferVersionsForm.propTypes = {
  setElement: propTypes.func,
  element: propTypes.any,
  onEditVersion: propTypes.func,
  isReadonly: propTypes.bool,
  disabled: propTypes.bool,
  onOpenAddNewVersionModal: propTypes.func,
  isAdd: propTypes.bool,
  referenceVersions: propTypes.array,
  onChangeVersion: propTypes.func,
};

export default CapitalBufferVersionsForm;
