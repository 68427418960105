import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

import {
  businessType as businessTypeRecoil,
  businessTypeComparison as businessTypeComparisonRecoil,
  businessTypeForRegion as businessTypeForRegionRecoil,
  businessTypeForRegionComparison as businessTypeForRegionComparisonRecoil,
} from 'state/global/businessType';
import useFetchBusinessType from 'hooks/api/businessType/useFetchBusinessType';
import useFetchBusinessTypeForRegion from 'hooks/api/businessType/useFetchBusinessTypeForRegion';
import useSaveBusinessType from 'hooks/api/businessType/useSaveBusinessType';

import UIInput from 'components/global/UICommon/UIInput';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';
import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIButtonCheckbox from 'components/global/UIButtons/UIButtonCheckbox';
import UIButtonSubmit from 'components/global/UIButtons/UIButtonSubmit';
import UIBackButton from 'components/global/UIButtons/UIBackButton';

import UITitle from 'components/KubenAdmin/UIAdmin/UITitle';
import UIInputWrapper from 'components/KubenAdmin/UIAdmin/UIInputWrapper';
import UIFoldableCard from 'components/KubenAdmin/UIAdmin/UIFoldableCard';
import UIModalWithoutSaveData from 'components/KubenAdmin/UIAdmin/UIModalWithoutSaveData';

import RegionAddNewVersionModal from 'components/KubenAdmin/RegionsPage/RegionAddNewVersionModal';
import CapitalBufferVersionsForm from 'components/KubenAdmin/BusinessValuesPage/CapitalBufferVersionsForm';
import BusinessValuesVersionsForm from 'components/KubenAdmin/BusinessValuesPage/BusinessValuesVersionsForm';

const BusinessValuesEditPage = () => {
  const { id, regionid } = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  const handlePercentageChange = (e) => {
    const newValue = Math.max(0, Math.min(100, Number(e.target.value)));
    setClientTime(newValue);
  };

  const {
    isLoading: isFetchBusinessTypeLoading,
    isError: isFetchBusinessTypeError,
    refetch: refetchBusinessType,
  } = regionid ? useFetchBusinessTypeForRegion(id, regionid) : useFetchBusinessType(id);

  const {
    isLoading: isSaveBusinessTypeLoading,
    isError: isSaveBusinessTypeError,
    isSuccess: isSaveBusinessTypeSuccess,
    mutate: mutateSaveBusinessType,
  } = useSaveBusinessType();

  const businessType = regionid ? businessTypeForRegionRecoil : businessTypeRecoil;

  const businessTypeComparison = regionid ? businessTypeForRegionComparisonRecoil : businessTypeComparisonRecoil;

  const [businessValues, setBusinessValues] = useRecoilState(businessType);
  const [businessValuesComparison, setBusinessValuesComparison] = useRecoilState(businessTypeComparison);

  const [name, setName] = useState('');
  const [limitAssessmentResult, setLimitAssessmentResult] = useState(false);
  const [clientTime, setClientTime] = useState(0);
  const [useCapacity, setUseCapacity] = useState(false);

  // handle versioning mechanism for capital buffer
  const [capitalBufferVersion, setCapitalBufferVersion] = useState();
  const [isEditCapitalBuffer, setIsEditCapitalBuffer] = useState(false);
  const [addNewCapitalBufferVersionModal, setAddNewCapitalBufferVersionModal] = useState(false);
  const [capitalBufferBlockedDate, setCapitalBufferBlockedDate] = useState({ year: null, month: null });

  // handle versioning mechanism for business values
  const [businessValuesVersion, setBusinessValuesVersion] = useState();
  const [unitTypes, setUnitTypes] = useState([]);
  const [nightStaffings, setNightStaffings] = useState([]);
  const [levels, setLevels] = useState([]);
  const [addNewVersionModal, setAddNewVersionModal] = useState(false);
  const [blockedDate, setBlockedDate] = useState({ year: null, month: null });

  const onSave = (closeOnSave) => {
    const updatedVersions = businessValues?.referenceDataVersions.map((version) => {
      if (version?.startDate === businessValuesVersion?.startDate) {
        return businessValuesVersion;
      }
      return version;
    });

    const updatedCapitalBufferVersions = businessValues?.capitalBufferRequirementReferenceData.map((version) => {
      if (version?.startDate === capitalBufferVersion?.startDate) {
        return capitalBufferVersion;
      }
      return version;
    });

    const updatedBusinessValues = {
      ...businessValues,
      reportName: name,
      limitAssessmentResultAutoEndDateToSameUnit: limitAssessmentResult,
      defaultNonClientFacingTimePercentage: clientTime,
      useCapacity: useCapacity,
      referenceDataVersions: updatedVersions,
      capitalBufferRequirementReferenceData: updatedCapitalBufferVersions,
    };

    mutateSaveBusinessType(updatedBusinessValues);
    setBusinessValues(updatedBusinessValues);
    setBusinessValuesComparison(updatedBusinessValues);

    if (closeOnSave) {
      history.goBack();
    }
  };

  const onCreateNewVersion = (date) => {
    setAddNewVersionModal(false);

    // format end date for current working version
    const newDate = new Date(date.year, date.month - 1, 0);
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const day = String(newDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    // add end date to last working version
    const updatedVersion = {
      ...businessValuesVersion,
      endDate: formattedDate,
    };

    // update all versions
    const updatedVersions = businessValues.referenceDataVersions.map((version) => {
      if (version.endDate === null) {
        return updatedVersion;
      }
      return version;
    });

    // get old business values for the new version
    const newUnitTypes = [...businessValuesVersion.unitTypeIds];

    const newNightStaffings = [...businessValuesVersion.nightStaffingIds];

    const newLevels = businessValuesVersion.levels.map((level) => ({
      ...level,
    }));

    // create new version of businessvalues
    const newVersion = {
      unitTypeIds: newUnitTypes,
      nightStaffingIds: newNightStaffings,
      levels: newLevels,
      staffings: businessValuesVersion.staffings,
      staffingByUnitTypeAndNightStaffings: businessValuesVersion.staffingByUnitTypeAndNightStaffings,
      startDate: date.year + '-' + (date.month <= 9 ? '0' + date.month : date.month) + '-01',
      endDate: null,
      id: uuidv4(),
    };

    // update businessvalues
    const updatedBusinessValues = {
      ...businessValues,
      referenceDataVersions: [...updatedVersions, newVersion],
    };

    setBusinessValues(updatedBusinessValues);
    setBusinessValuesVersion(newVersion);
  };

  const onCreateNewCapitalBufferVersion = (date) => {
    setAddNewCapitalBufferVersionModal(false);

    // format end date for current working capital buffer version
    const newDate = new Date(date.year, date.month - 1, 0);
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const day = String(newDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    // add end date to last working capital buffer version
    const updatedCapitalBufferVersion = {
      ...capitalBufferVersion,
      endDate: formattedDate,
    };

    // update all versions of capitalbuffer
    const updatedCapitalBufferVersions = businessValues.capitalBufferRequirementReferenceData.map((version) => {
      if (version.endDate === null) {
        return updatedCapitalBufferVersion;
      }
      return version;
    });

    // get old capital buffer value for the new version
    const newCapitalBuffer = capitalBufferVersion.capitalBuffer;

    // create new version of capital buffer
    const newCapitalBufferVersion = {
      capitalBuffer: newCapitalBuffer,
      startDate: date.year + '-' + (date.month <= 9 ? '0' + date.month : date.month) + '-01',
      endDate: null,
      id: uuidv4(),
    };

    // update businessvalues
    const updatedBusinessValues = {
      ...businessValues,
      capitalBufferRequirementReferenceData: [...updatedCapitalBufferVersions, newCapitalBufferVersion],
    };

    setBusinessValues(updatedBusinessValues);
    setCapitalBufferVersion(newCapitalBufferVersion);
  };

  const onChangeVersion = async (startDate) => {
    onSaveVersionChanges();
    // set new version to edit
    const newVersionToEdit = await businessValues.referenceDataVersions.find((el) => el.startDate === startDate);
    setBusinessValuesVersion({ ...newVersionToEdit });
    setUnitTypes(newVersionToEdit.unitTypeIds);
    setNightStaffings(newVersionToEdit.nightStaffingIds);
    setLevels(newVersionToEdit.levels);
  };

  // function to save changes in current version to edit
  const onSaveVersionChanges = () => {
    const updatedVersions = businessValues?.referenceDataVersions.map((version) => {
      if (version?.startDate === businessValuesVersion?.startDate) {
        return businessValuesVersion;
      }
      return version;
    });

    setBusinessValues((prevBusinessValues) => ({
      ...prevBusinessValues,
      referenceDataVersions: updatedVersions,
    }));
  };

  const onChangeCapitalBufferVersion = async (startDate) => {
    onSaveCapitalBufferVersionChanges();
    // set new capital buffer version to edit
    const newCapitalBufferVersionToEdit = await businessValues.capitalBufferRequirementReferenceData.find(
      (el) => el.startDate === startDate,
    );
    setCapitalBufferVersion({ ...newCapitalBufferVersionToEdit });
  };

  const onSaveCapitalBufferVersionChanges = () => {
    const updatedCapitalBufferVersions = businessValues?.capitalBufferRequirementReferenceData.map((version) => {
      if (version?.startDate === capitalBufferVersion?.startDate) {
        return capitalBufferVersion;
      }
      return version;
    });

    setBusinessValues((prevBusinessValues) => ({
      ...prevBusinessValues,
      capitalBufferRequirementReferenceData: updatedCapitalBufferVersions,
    }));
  };

  useEffect(() => {
    if (Object.keys(businessValues).length != 0) {
      setName(businessValues.reportName);
      setLimitAssessmentResult(businessValues.limitAssessmentResultAutoEndDateToSameUnit);
      setClientTime(businessValues.defaultNonClientFacingTimePercentage);
      setUseCapacity(businessValues.useCapacity);
    }
  }, [businessValues.id]);

  useEffect(() => {
    if (Object.keys(businessValues).length != 0) {
      const currentBusinessValuesVersion = businessValues.referenceDataVersions.find(
        (version) => version.endDate === null,
      );
      setBusinessValuesVersion(currentBusinessValuesVersion);

      setUnitTypes(currentBusinessValuesVersion.unitTypeIds);
      setNightStaffings(currentBusinessValuesVersion.nightStaffingIds);
      setLevels(currentBusinessValuesVersion.levels);
    }
  }, [businessValues.id, businessValues.capitalBufferRequirementReferenceData]);

  useEffect(() => {
    if (Object.keys(businessValues).length != 0) {
      const currentCapitalBufferVersion = businessValues.capitalBufferRequirementReferenceData.find(
        (version) => version.endDate === null,
      );
      setCapitalBufferVersion(currentCapitalBufferVersion);
    }
  }, [businessValues.id, businessValues.referenceDataVersions]);

  useEffect(() => {
    if (capitalBufferVersion?.startDate != null) {
      const date = new Date(capitalBufferVersion.startDate);
      setCapitalBufferBlockedDate({ year: date.getFullYear(), month: date.getMonth() + 1 });
    }
  }, [addNewCapitalBufferVersionModal]);

  useEffect(() => {
    if (businessValuesVersion?.startDate != null) {
      const date = new Date(businessValuesVersion.startDate);
      setBlockedDate({ year: date.getFullYear(), month: date.getMonth() + 1 });
    }
  }, [addNewVersionModal]);

  useEffect(() => {
    if (businessValuesVersion) {
      setUnitTypes(businessValuesVersion.unitTypeIds);
      setNightStaffings(businessValuesVersion.nightStaffingIds);
      setLevels(businessValuesVersion.levels);
    }
  }, [businessValuesVersion]);

  useEffect(() => {
    setBusinessValuesComparison((prev) => ({
      ...prev,
      limitAssessmentResultAutoEndDateToSameUnit: limitAssessmentResult,
      defaultNonClientFacingTimePercentage: clientTime,
      useCapacity: useCapacity,
      referenceDataVersions: prev.referenceDataVersions?.map((version) =>
        version.startDate === businessValuesVersion?.startDate
          ? { ...businessValuesVersion, levels: levels, unitTypeIds: unitTypes, nightStaffingIds: nightStaffings }
          : version,
      ),
      capitalBufferRequirementReferenceData: prev.capitalBufferRequirementReferenceData?.map((version) =>
        version.startDate === capitalBufferVersion?.startDate ? capitalBufferVersion : version,
      ),
    }));
  }, [
    limitAssessmentResult,
    clientTime,
    useCapacity,
    unitTypes,
    nightStaffings,
    levels,
    capitalBufferVersion,
    businessValuesVersion,
  ]);

  useEffect(() => {
    if (isSaveBusinessTypeSuccess) {
      refetchBusinessType();
    }
  }, [isSaveBusinessTypeSuccess]);

  if (isFetchBusinessTypeLoading || isSaveBusinessTypeLoading) {
    return <UILoadingModal />;
  }

  if (isFetchBusinessTypeError || isSaveBusinessTypeError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <>
      <UIModalWithoutSaveData
        elementToCompare={businessValuesComparison}
        orginalElement={businessValues}
      />
      <UIStaticComponentsWrapper isAdmin={true}>
        <div className="space-y-5 w-full">
          <div className="relative flex items-center pb-4">
            <div className="cursor-pointer flex items-center whitespace-nowrap">
              <UIBackButton />
            </div>
            <div className="absolute left-1/2 transform -translate-x-1/2">
              <UITitle title={`${businessValues.name} ${businessValues.region?.name || ''}`} />
            </div>
          </div>
          <UIFoldableCard
            title="Egenskaper"
            defaultOpen={true}
          >
            <UIInputWrapper title="Rapportnamn">
              <UIInput
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                disabled={true}
              />
            </UIInputWrapper>
            <UIInputWrapper title="Sätt automatiskt stopp datum enbart på bedömningar på samma enhet">
              <UIButtonCheckbox
                checked={limitAssessmentResult}
                setChecked={setLimitAssessmentResult}
                isDisabledPermissions={businessValues.region !== null}
                styleDesc={'text-sm md:text-xs'}
              />
            </UIInputWrapper>
            <UIInputWrapper title="Kringtid i procent">
              <div className="relative">
                <UIInput
                  value={clientTime}
                  onChange={handlePercentageChange}
                  type="number"
                  disabled={businessValues.region !== null}
                />
                <div className="absolute right-2 top-1/2 -translate-y-1/2 text-lg font-semibold">%</div>
              </div>
            </UIInputWrapper>
            <UIInputWrapper title="använd kapacitet">
              <UIButtonCheckbox
                checked={useCapacity}
                setChecked={setUseCapacity}
                isDisabledPermissions={businessValues.region !== null}
                styleDesc={'text-sm md:text-xs'}
              />
            </UIInputWrapper>
          </UIFoldableCard>
          <CapitalBufferVersionsForm
            referenceVersions={businessValues.capitalBufferRequirementReferenceData}
            element={capitalBufferVersion || {}}
            setElement={setCapitalBufferVersion}
            onEditVersion={() => setIsEditCapitalBuffer(!isEditCapitalBuffer)}
            isReadonly={!isEditCapitalBuffer}
            disabled={businessValues.region === null}
            onOpenAddNewVersionModal={() => setAddNewCapitalBufferVersionModal(true)}
            onChangeVersion={onChangeCapitalBufferVersion}
          />
          <BusinessValuesVersionsForm
            referenceVersions={businessValues.referenceDataVersions}
            element={businessValuesVersion || {}}
            unitTypes={unitTypes}
            nightStaffings={nightStaffings}
            levels={levels}
            setElement={setBusinessValuesVersion}
            onOpenAddNewVersionModal={() => setAddNewVersionModal(true)}
            onChangeVersion={onChangeVersion}
          />
          <div className="flex w-full space-x-5 justify-center">
            <UIButtonSubmit
              name="Spara"
              variant="button-primary"
              assessments={true}
              isIcon={false}
              onSubmit={() => onSave(false)}
            />
            <UIButtonSubmit
              name="Spara och stäng"
              variant="bg-app-assessment"
              assessments={true}
              isIcon={false}
              onSubmit={() => onSave(true)}
            />
          </div>
        </div>
      </UIStaticComponentsWrapper>
      {addNewCapitalBufferVersionModal && (
        <RegionAddNewVersionModal
          onClose={() => setAddNewCapitalBufferVersionModal(false)}
          onSave={onCreateNewCapitalBufferVersion}
          blockedDate={capitalBufferBlockedDate}
        />
      )}
      {addNewVersionModal && (
        <RegionAddNewVersionModal
          onClose={() => setAddNewVersionModal(false)}
          onSave={onCreateNewVersion}
          blockedDate={blockedDate}
        />
      )}
    </>
  );
};

export default BusinessValuesEditPage;
