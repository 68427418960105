/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useRecoilState } from 'recoil';

import filterTemplateAnswers from 'utils/KubenAssessments/filterTemplateAnswers';
import getModifiedPersonAssessment from 'utils/KubenAssessments/getModifiedPersonAssessment';
import getAdditionalQuestionInfo from 'utils/KubenAssessments/getAdditionalQuestionInfo';
import checkIfAnswerIsBlocked from 'utils/KubenAssessments/checkIfAnswerIsBlocked';

import { chosenTemplate } from 'state/global/templates';
import { chosenRegion } from 'state/KubenAssessments/choosingTemplate/region';
import { currentQuestionState } from 'state/KubenAssessments/questions/currentQuestions';
import { regionUnits as regionUnitsRecoil } from 'state/KubenAssessments/assessments/regionUnits';
import { latestAnsweredAssessment as latestAnsweredAssessmentRecoil } from 'state/KubenAssessments/questions/latestAnsweredAssessment';
import { chosenAssessmentAction as chosenAssessmentActionRecoil } from 'state/KubenAssessments/chosenAssessmentAction/chosenAssessmentAction';
import { answersAssessment } from 'state/KubenAssessments/assessments/answersAssessment';
import { templateAssessment } from 'state/KubenAssessments/assessments/templateAssessment';
import { selectedPerson as selectedPersonRecoil } from 'state/KubenAssessments/saved/savedSelectedPerson';
import { isDesirableAnswerEnabled as isDesirableAnswerEnabledRecoil } from 'state/KubenAssessments/questions/isDesirableAnswerEnabled';

import useFetchRegionUnits from 'hooks/api/assessment/useFetchRegionUnits';
import useSaveIncompleteAssessment from 'hooks/api/assessmentResult/useSaveIncompleteAssessment';
import useRecalculateAssessment from 'hooks/api/assessmentResult/useRecalculateAssessment';
import useFetchAnswersAssessment from 'hooks/api/assessment/useFetchAnswersAssessment';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';

import UINameHolder from 'components/KubenAssessments/UIAssessments/UINameHolder';
import Question from 'components/KubenAssessments/QuestionsPage/Question';
import QuestionButtons from 'components/KubenAssessments/QuestionsPage/QuestionButtons';
import QuestionsPageProgressBar from 'components/KubenAssessments/QuestionsPage/QuestionsPageProgressBar';
import QuestionsPageNavigationTab from 'components/KubenAssessments/QuestionsPage/QuestionsPageNavigationTab/QuestionsPageNavigationTab';
import QuestionsPageDescriptionTab from 'components/KubenAssessments/QuestionsPage/QuestionsPageDescriptionTab';
import BlockedAnswerModal from 'components/KubenAssessments/QuestionsPage/QuestionsPageModals/BlockedAnswerModal';

const QuestionsPage = () => {
  const { t } = useTranslation();

  // saved data
  const selectedPerson = useRecoilValue(selectedPersonRecoil);
  const chosenAssessmentAction = useRecoilValue(chosenAssessmentActionRecoil);
  const choosenRegionData = useRecoilValue(chosenRegion);
  const choosenTemplateData = useRecoilValue(chosenTemplate);
  const regionUnits = useRecoilValue(regionUnitsRecoil);
  const [latestAnsweredAssessment, setLatestAnsweredAssessment] = useRecoilState(latestAnsweredAssessmentRecoil);
  const [isDesirableAnswerEnabled, setIsDesirableAnswerEnabled] = useRecoilState(isDesirableAnswerEnabledRecoil);

  // fetch assessment
  const {
    isLoading: isAnswersAssessmentLoading,
    isError: isAnswersAssessmentError,
    refetch,
  } = useFetchAnswersAssessment(
    chosenAssessmentAction,
    selectedPerson?.id,
    choosenTemplateData?.id,
    choosenRegionData?.id,
  );
  const [answersAssessmentData, setAnswersAssessmentData] = useRecoilState(answersAssessment);
  const templateAssessmentData = useRecoilValue(templateAssessment);

  // post assessment
  const {
    isError: isPostIncompleteAssessmentError,
    isSuccess: isPostIncompleteAssessmentSuccess,
    mutate: mutatePostIncompleteAssessment,
    data: incompleteAssessmentResponse,
  } = useSaveIncompleteAssessment();

  const {
    isError: isRecalculateAssessmentError,
    isSuccess: isRecalculateAssessmentSuccess,
    mutate: mutateRecalculateAssessment,
  } = useRecalculateAssessment();

  // fetch units
  const {
    isLoading: isRegionUnitsLoading,
    isError: isRegionUnitsError,
    mutate: mutateRegionUnits,
  } = useFetchRegionUnits(choosenTemplateData?.id, choosenRegionData?.id);

  // current data
  const [questionsInCurrentGroup, setQuestionsInCurrentGroup] = useState([]);
  const [allGroupsIds, setAllGroupsIds] = useState([]);
  const [currentGroupIdx, setCurrentGroupIdx] = useState();
  const [currentQuestionIdx, setCurrentQuestionIdx] = useState();
  const [currentUserNavigationAction, setCurrentUserNavigationAction] = useState('');
  const [currentQuestion, setCurrentQuestion] = useRecoilState(currentQuestionState);
  const [currentQuestionInformation, setCurrentQuestionInformation] = useState({
    title: '',
    question: '',
    description: '',
    questionType: '',
    additionalInfo: {},
    answers: [],
    nmbOfQuestions: 0,
    nmbOfAnswers: 0,
  });
  const [currentQuestionUserAnswers, setCurrentQuestionUserAnswers] = useState([]);
  const [isFirstAnsweringState, setIsFirstAnsweringState] = useState(true);

  // form mechanism
  const [isNavigationTabVisible, setIsNavigationTabVisible] = useState(true);
  const [selectedTabToDisplay, setSelectedTabToDisplay] = useState('navigation');
  const history = useHistory();

  // blocked answers mechanism
  const [previousQuestionId, setPreviousQuestionId] = useState();
  const [toggleBlockedAnswerModal, setToggleBlockedAnswerModal] = useState(false);
  const [blockedAnswerContent, setToggleAnswerContent] = useState('');

  useEffect(() => {
    if (incompleteAssessmentResponse?.data != null && isPostIncompleteAssessmentSuccess) {
      const response = checkIfAnswerIsBlocked(
        incompleteAssessmentResponse?.data?.answers.find((el) => el.question.id === previousQuestionId),
        templateAssessmentData.questions.find((el) => el.id === previousQuestionId),
      );
      if (response != null) {
        setToggleBlockedAnswerModal(true);
        setToggleAnswerContent(response);
      }
    }
  }, [incompleteAssessmentResponse?.data, isPostIncompleteAssessmentSuccess]);

  const closeAnsRedirect = () => {
    setToggleBlockedAnswerModal(false);
    history.push('/bedomningar/');
  };

  useEffect(() => {
    if (Object.keys(choosenRegionData).length === 0 || Object.keys(choosenTemplateData).length === 0) {
      return;
    } else if (!answersAssessmentData?.id || latestAnsweredAssessment !== answersAssessmentData?.id) {
      refetch();
    }
  }, [selectedPerson, choosenRegionData, choosenTemplateData]);

  // fetching units for unit question type
  useEffect(() => {
    if (choosenTemplateData?.id && choosenRegionData?.id) {
      // check if saved regionUnits are diffrent to determine if we need to fetch new ones
      if (regionUnits?.templateId !== choosenTemplateData?.id || regionUnits?.regionId !== choosenRegionData?.id) {
        mutateRegionUnits();
      }
    }
  }, [choosenTemplateData, choosenRegionData]);

  const handleQuestionNavigation = () => {
    const tmpQuestionsInCurrentGroup = answersAssessmentData?.applicableQuestionsByGroup.filter(
      (el) => el.questionGroupId == currentQuestion.groupId,
    )[0].applicableQuestions;
    const isLastQuestionInGroup = tmpQuestionsInCurrentGroup[tmpQuestionsInCurrentGroup.length - 1];
    const isLastGroup = allGroupsIds[allGroupsIds.length - 1];
    const isFirstQuestionInGroup = tmpQuestionsInCurrentGroup[tmpQuestionsInCurrentGroup.length + 1];

    // next questions
    if (currentUserNavigationAction === 'nextQuestion') {
      if (isLastQuestionInGroup === currentQuestion.questionId && isLastGroup === currentQuestion.groupId) {
        history.push('/assessments/questions-summary');
        return;
      }
      if (isLastQuestionInGroup !== currentQuestion.questionId) {
        setCurrentQuestion({
          groupId: currentQuestion.groupId,
          questionId: tmpQuestionsInCurrentGroup[currentQuestionIdx + 1],
        });
      }
      if (isLastQuestionInGroup === currentQuestion.questionId) {
        setQuestionsInCurrentGroup(
          answersAssessmentData?.applicableQuestionsByGroup[currentGroupIdx + 1].applicableQuestions,
        );
        setCurrentQuestion({
          groupId: answersAssessmentData?.applicableQuestionsByGroup[currentGroupIdx + 1].questionGroupId,
          questionId: answersAssessmentData?.applicableQuestionsByGroup[currentGroupIdx + 1].applicableQuestions[0],
        });
      }
      setCurrentUserNavigationAction('');
    }

    // previous question
    if (currentUserNavigationAction === 'previousQuestion') {
      if (isFirstQuestionInGroup !== currentQuestion.questionId) {
        setCurrentQuestion({
          groupId: currentQuestion.groupId,
          questionId: questionsInCurrentGroup[currentQuestionIdx - 1],
        });
      }
      if (currentQuestion.questionId === questionsInCurrentGroup[0]) {
        setQuestionsInCurrentGroup(
          answersAssessmentData?.applicableQuestionsByGroup[currentGroupIdx - 1].applicableQuestions,
        );
        setCurrentQuestion({
          groupId: allGroupsIds[currentGroupIdx - 1],
          questionId:
            answersAssessmentData?.applicableQuestionsByGroup[currentGroupIdx - 1].applicableQuestions[
              answersAssessmentData?.applicableQuestionsByGroup[currentGroupIdx - 1].applicableQuestions.length - 1
            ],
        });
      }
      setCurrentUserNavigationAction('');
    }
  };

  // handle navigation and set current question info
  useEffect(() => {
    if (!isPostIncompleteAssessmentSuccess) return;
    handleQuestionNavigation();
  }, [isPostIncompleteAssessmentSuccess]);

  useEffect(() => {
    if (!isRecalculateAssessmentSuccess) return;
    handleQuestionNavigation();
  }, [isRecalculateAssessmentSuccess]);

  // set starting question on first init
  useEffect(() => {
    if (Object.keys(answersAssessmentData).length !== 0) {
      const groupIds = answersAssessmentData?.applicableQuestionsByGroup.map((el) => el.questionGroupId);
      setAllGroupsIds(groupIds);
      setQuestionsInCurrentGroup(answersAssessmentData?.applicableQuestionsByGroup[0].applicableQuestions);
      // checking if user is coming back from summary to redirect him to latest visited question
      if (!currentQuestion?.questionId || latestAnsweredAssessment !== answersAssessmentData?.id) {
        setCurrentQuestion({
          groupId: answersAssessmentData?.applicableQuestionsByGroup[0]?.questionGroupId,
          questionId: answersAssessmentData?.applicableQuestionsByGroup[0]?.applicableQuestions[0],
        });
        setLatestAnsweredAssessment(answersAssessmentData?.id);
      }
      setIsDesirableAnswerEnabled(answersAssessmentData.isDesirableVisible);
    }
  }, [answersAssessmentData]);

  // set needed information for current question
  useEffect(() => {
    if (
      currentQuestion != null &&
      Object.keys(answersAssessmentData).length !== 0 &&
      Object.keys(templateAssessmentData).length !== 0 &&
      allGroupsIds.length > 0
    ) {
      const tmpQuetionsInCurrentGroup = answersAssessmentData?.applicableQuestionsByGroup.filter(
        (el) => el.questionGroupId == currentQuestion.groupId,
      )[0].applicableQuestions;
      setQuestionsInCurrentGroup(tmpQuetionsInCurrentGroup);
      setCurrentGroupIdx(allGroupsIds.indexOf(currentQuestion.groupId));
      setCurrentQuestionIdx(tmpQuetionsInCurrentGroup.indexOf(currentQuestion.questionId));
      setInfoForCurrentQuestion();
    }
  }, [currentQuestion, templateAssessmentData, allGroupsIds]);

  useEffect(() => {
    setCurrentQuestionUserAnswers(
      answersAssessmentData?.answers?.find((el) => el?.question?.id === currentQuestion?.questionId),
    );
  }, [currentQuestion, answersAssessmentData]);

  const onNextQuestion = () => {
    setPreviousQuestionId(currentQuestion.questionId);
    setCurrentUserNavigationAction('nextQuestion');
    mutateAssessment(answersAssessmentData);
  };

  const onPreviousQuestion = () => {
    setPreviousQuestionId(currentQuestion.questionId);
    if (currentQuestionIdx === 0 && currentGroupIdx === 0) {
      return;
    }
    setCurrentUserNavigationAction('previousQuestion');
    mutateAssessment(answersAssessmentData);
  };

  const handleClickFromProgressBar = (groupId, questionId) => {
    setPreviousQuestionId(currentQuestion.questionId);
    mutateAssessment(answersAssessmentData);
    const findQuestionsInGroup = answersAssessmentData?.applicableQuestionsByGroup.filter(
      (el) => el.questionGroupId == groupId,
    );
    setQuestionsInCurrentGroup(findQuestionsInGroup[0].applicableQuestions);
    setCurrentQuestion({
      groupId: groupId,
      questionId: questionId,
    });
  };

  const handleAnswerClick = (questionId, action, selectedAnswer) => {
    const tmpPersonAssessment = getModifiedPersonAssessment(
      questionId,
      action,
      selectedAnswer,
      answersAssessmentData,
      isFirstAnsweringState,
    );
    setAnswersAssessmentData(tmpPersonAssessment);
    setIsFirstAnsweringState(isDesirableAnswerEnabled ? !isFirstAnsweringState : true);
  };

  const handleDesirableCheckboxClicked = () => {
    const tmpPersonAssessment = getModifiedPersonAssessment(
      null,
      'isDesirableVisible',
      !isDesirableAnswerEnabled,
      answersAssessmentData,
    );
    setAnswersAssessmentData(tmpPersonAssessment);
    setIsDesirableAnswerEnabled(!isDesirableAnswerEnabled);
    setIsFirstAnsweringState(true);
  };

  const mutateAssessment = (assessmentData) => {
    if (assessmentData.state.id === 'Completed') {
      mutateRecalculateAssessment(assessmentData);
    } else {
      mutatePostIncompleteAssessment(answersAssessmentData);
    }
  };

  const handleClickFromNavigationTab = (action, groupId, questionId) => {
    switch (action) {
      case 'summary': {
        setPreviousQuestionId(currentQuestion.questionId);
        mutateAssessment(answersAssessmentData);
        history.push('questions-summary');
        break;
      }
      case 'toggle': {
        setIsNavigationTabVisible(!isNavigationTabVisible);
        break;
      }
      case 'question': {
        setPreviousQuestionId(currentQuestion.questionId);
        mutateAssessment(answersAssessmentData);
        const findQuestionsInGroup = answersAssessmentData?.applicableQuestionsByGroup.filter(
          (el) => el.questionGroupId == groupId,
        );
        setQuestionsInCurrentGroup(findQuestionsInGroup[0].applicableQuestions);
        setCurrentQuestion({
          groupId: groupId,
          questionId: questionId,
        });
      }
    }
  };

  const setInfoForCurrentQuestion = () => {
    const groupName = templateAssessmentData.questionGroups.find(
      (questionGroup) => questionGroup.id === currentQuestion.groupId,
    );
    const questionInfo = templateAssessmentData.questions.find(
      (questionId) => questionId.id === currentQuestion.questionId,
    );
    const tmpQuetionsInCurrentGroup = answersAssessmentData?.applicableQuestionsByGroup.filter(
      (el) => el.questionGroupId == currentQuestion.groupId,
    )[0].applicableQuestions;

    setCurrentQuestionInformation({
      title: groupName?.name,
      question: questionInfo?.name,
      description: questionInfo?.helpText,
      questionType: questionInfo?.type,
      isRequired: questionInfo?.answerRequired,
      isAnsweredExternally: questionInfo?.answeredExternally,
      answers: filterTemplateAnswers(questionInfo, templateAssessmentData, regionUnits),
      additionalInfo: getAdditionalQuestionInfo(questionInfo, answersAssessmentData),
      nmbOfQuestions: tmpQuetionsInCurrentGroup.length,
      nmbOfAnswers: questionInfo?.type !== 'Judged' ? 1 : 2,
    });
  };

  const handleTabSwitch = () => {
    setIsNavigationTabVisible(true);
    setSelectedTabToDisplay('description');
  };

  if (
    !currentQuestion ||
    isAnswersAssessmentLoading ||
    currentQuestionInformation == null ||
    currentQuestionIdx == null ||
    isRegionUnitsLoading
  ) {
    return <UILoadingModal />;
  }

  if (
    isAnswersAssessmentError ||
    isPostIncompleteAssessmentError ||
    isRecalculateAssessmentError ||
    isRegionUnitsError
  ) {
    return <UIErrorModal message={t('UIModals.errorModalMessage')} />;
  }

  return (
    <div className="flex flex-col flex-grow h-full">
      <div className="h-full w-full flex justify-center">
        <div className="h-full w-full flex flex-col flex-grow">
          {selectedPerson && Object.keys(selectedPerson).length !== 0 && (
            <UINameHolder
              id={selectedPerson?.id}
              fullName={selectedPerson?.name}
              idNumber={selectedPerson?.personId}
              gender={selectedPerson?.gender}
              customerNumber={selectedPerson?.customerNo}
              socialSecurityNumber={selectedPerson?.socialSecurityNumber}
              reportId={selectedPerson?.reportId}
              isButton={false}
            />
          )}
          <div className="py-8">
            <QuestionsPageProgressBar
              questionGroups={answersAssessmentData?.applicableQuestionsByGroup}
              currentQuestion={currentQuestion}
              handleClick={handleClickFromProgressBar}
            />
          </div>
          <Question
            title={currentQuestionInformation?.title}
            question={currentQuestionInformation?.question}
            description={currentQuestionInformation?.description}
            questionType={currentQuestionInformation?.questionType}
            questionId={currentQuestion?.questionId}
            answers={currentQuestionInformation?.answers}
            nmbOfQuestionsInGroup={currentQuestionInformation?.nmbOfQuestions}
            additionalInfo={currentQuestionInformation?.additionalInfo}
            currentQuestionIndex={currentQuestionIdx + 1}
            handleClick={handleAnswerClick}
            onTabSwitch={() => handleTabSwitch()}
            isDesirableAnswerEnabled={isDesirableAnswerEnabled}
            isCircleProgressBarEnabled={selectedPerson && Object.keys(selectedPerson).length !== 0 ? false : true}
            onDesirableCheckboxClick={handleDesirableCheckboxClicked}
            userAnswers={currentQuestionUserAnswers}
            isRequired={currentQuestionInformation?.isRequired}
            isAnsweredExternally={currentQuestionInformation?.isAnsweredExternally}
            answerDescriptions={currentQuestionInformation}
          />
          <QuestionButtons
            onNextQuestion={onNextQuestion}
            onPreviousQuestion={onPreviousQuestion}
            currentQuestionIdx={currentQuestionIdx}
            currentGroupIdx={currentGroupIdx}
          />
        </div>
        <div
          className={`${isNavigationTabVisible ? 'width-navbar-transition-open' : 'width-navbar-transition-closed'}`}
        ></div>
        <div
          className={`mt-4 ml-[40px] mr-8 absolute questionPosition right-0 flex justify-end items-start g-red-200 
           pb-10 ${isNavigationTabVisible ? 'w-[350px]' : 'w-auto'}`}
        >
          {selectedTabToDisplay === 'navigation' && (
            <QuestionsPageNavigationTab
              currentQuestion={currentQuestion}
              subTitle={choosenRegionData.name}
              isVisible={isNavigationTabVisible}
              handleClick={handleClickFromNavigationTab}
              answersAssessment={answersAssessmentData}
              templateAssessment={templateAssessmentData}
            />
          )}
          {selectedTabToDisplay === 'description' && (
            <QuestionsPageDescriptionTab
              title={t('QuestionsPage.answerDescriptionTitle')}
              onCloseClick={() => setSelectedTabToDisplay('navigation')}
              data={currentQuestionInformation}
            />
          )}
        </div>
      </div>
      {toggleBlockedAnswerModal && (
        <BlockedAnswerModal
          onCloseModal={closeAnsRedirect}
          content={blockedAnswerContent}
        />
      )}
    </div>
  );
};

export default QuestionsPage;
