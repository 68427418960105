import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/businessType';
import { nightStaffings as nightStaffingsRecoil } from 'state/global/businessTypes';

const useFetchNightStaffings = () => {
  const setNightStaffings = useSetRecoilState(nightStaffingsRecoil);

  return query.useQueryWrapper(
    'fetchNightStaffings',
    async () => {
      const nightStaffings = await actions.fetchNightStaffings();
      setNightStaffings(nightStaffings);
      return nightStaffings;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchNightStaffings;
