import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { searchQuery } from 'state/global/searchQuery';
import { businessTypesFiltered } from 'state/global/businessTypes';
import useFetchBusinessTypes from 'hooks/api/businessType/useFetchBusinessTypes';

import tableElements from 'utils/KubenAdmin/BusinessValuesPage/tableElements';
import businessValuesTableMenu from 'utils/KubenAdmin/BusinessValuesPage/businessValuesTableMenu';

import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';
import UIInput from 'components/global/UICommon/UIInput';
import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';

import UICardWrapper from 'components/KubenAdmin/UIAdmin/UICardWrapper';
import UITitle from 'components/KubenAdmin/UIAdmin/UITitle';
import UITable from 'components/KubenAdmin/UITable/UITable';
import UITableElementWrapper from 'components/KubenAdmin/UITable/UITableElementWrapper';
import UITableElement from 'components/KubenAdmin/UITable/UITableElement';
import UITableMenu from 'components/KubenAdmin/UITable/UITableMenu';

const BusinessValuesPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [search, setSearch] = useRecoilState(searchQuery);
  const businessStaircasesFiltered = useRecoilValue(businessTypesFiltered);
  const [selected, setSelected] = useState(null);

  const { isLoading: isBusinessTypesLoading, isError: isBusinessTypesError } = useFetchBusinessTypes();

  const handleClick = (type, id, regionid) => {
    if (type === 'editStair') {
      if (regionid !== undefined) {
        history.push(`/admin/businessvalues/staircase/${id}/${regionid}`);
      } else {
        history.push(`/admin/businessvalues/staircase/${id}`);
      }
    } else if (type === 'edit') {
      if (regionid !== undefined) {
        history.push(`/admin/businessvalues/${id}/${regionid}`);
      } else {
        history.push(`/admin/businessvalues/${id}`);
      }
    }
  };

  if (isBusinessTypesLoading) {
    return <UILoadingModal />;
  }

  if (isBusinessTypesError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <UIStaticComponentsWrapper isAdmin={true}>
      <UITitle
        title="VERKSAMHETSVÄRDEN"
        isButton={false}
      />
      <UICardWrapper title="Sök på behovstrappor efter verksamhetsområde">
        <UIInput
          type="search"
          placeholder="Sök"
          value={search}
          onChange={(text) => setSearch(text)}
        />
      </UICardWrapper>
      <UITable tableElements={tableElements}>
        {businessStaircasesFiltered.length > 0 ? (
          businessStaircasesFiltered.map((el, idx) => {
            const activeVersion = el.referenceDataVersions[el.referenceDataVersions.length - 1];
            return (
              <UITableElementWrapper key={idx}>
                <UITableElement
                  name={el.name}
                  maxLength={100}
                />
                <UITableElement
                  name={el.region?.name}
                  maxLength={100}
                />
                <UITableElement
                  name={activeVersion.startDate}
                  maxLength={100}
                />
                <UITableMenu
                  handleClick={(type) => handleClick(type, el.id, el.region?.id)}
                  isOpen={selected?.id === el.id && selected?.region?.id === el.region?.id}
                  setOpen={() =>
                    setSelected(selected?.id === el.id && selected?.region.id === el.region?.id ? null : el)
                  }
                  data={businessValuesTableMenu}
                />
              </UITableElementWrapper>
            );
          })
        ) : (
          <div className="py-8">Inga värden att visa</div>
        )}
      </UITable>
    </UIStaticComponentsWrapper>
  );
};

export default BusinessValuesPage;
