import { useSetRecoilState } from 'recoil';
import mutation from '../useQueryWrapper';
import actions from 'api/assessmentResult';
// eslint-disable-next-line max-len
import { assessmentHistory as assessmentHistoryRecoil } from 'state/KubenAssessments/assessmentHistory/assessmentHistory';

const useFetchPersonAssessmentHistory = (id, type, pageSize, page) => {
  const setAssessmentHistory = useSetRecoilState(assessmentHistoryRecoil);

  return mutation.useMutationWrapper(
    async () => {
      const assessmentHistory = await actions.fetchAssessmentHistory(id, type, pageSize, page);
      setAssessmentHistory(assessmentHistory.data);
      return assessmentHistory;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
};

export default useFetchPersonAssessmentHistory;
