import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilState, useRecoilValue } from 'recoil';

import { aggregationMethods, templateTabState, templateFormState, statisticsCodes } from 'state/KubenAdmin/templates';

import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';
import UIButtonCheckbox from '../../global/UIButtons/UIButtonCheckbox';
import UIButtonDropdownGroup from '../../global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from '../../global/UIButtons/UIItemDropdown';
import UIInput from '../../global/UICommon/UIInput';
import UIInputWrapper from '../UIAdmin/UIInputWrapper';

const TemplateGroupPropertiesForm = ({ index }) => {
  const allAggregationMethods = useRecoilValue(aggregationMethods);
  const allStatisticsCodes = useRecoilValue(statisticsCodes);
  const [template, setTemplate] = useRecoilState(templateFormState);
  const [tabState, setTabState] = useRecoilState(templateTabState);

  const group = template.questionGroups[index];

  const onChange = (name, value) => {
    const tmpTemplate = { ...template };
    const tmpGroups = [...tmpTemplate.questionGroups];
    const tmpGroup = { ...tmpGroups[index] };

    tmpGroup[name] = value;
    tmpGroups[index] = tmpGroup;

    tmpTemplate.questionGroups = tmpGroups;
    setTemplate(tmpTemplate);
  };

  return (
    <div>
      <UIInputWrapper title="Namn">
        <UIInput
          value={group.name}
          onChange={(e) => onChange('name', e.target.value)}
          type="text"
        />
      </UIInputWrapper>
      <UIInputWrapper title="Beskrivning">
        <UIInput
          value={group.description}
          onChange={(e) => onChange('description', e.target.value)}
          type="text"
        />
      </UIInputWrapper>
      <UIInputWrapper title="Kategori">
        <UIInput
          value={group.category}
          onChange={(e) => onChange('category', e.target.value)}
          type="text"
        />
      </UIInputWrapper>
      <UIInputWrapper title="Kod">
        <UIInput
          value={group.code}
          onChange={(e) => onChange('code', e.target.value)}
          type="text"
        />
      </UIInputWrapper>
      <UIInputWrapper title="Kvalitativ">
        <UIButtonCheckbox
          setChecked={(checked) => onChange('isQualityGroup', checked)}
          checked={group.isQualityGroup}
        />
      </UIInputWrapper>
      <UIInputWrapper title="Aggregeringsmethod">
        <UIButtonDropdownGroup
          translate={false}
          value={allAggregationMethods.find((sel) => sel.id === group.aggregationMethod)?.value}
        >
          {allAggregationMethods.map((sel, idx) => {
            return (
              <UIItemDropdown
                value={sel.value}
                displayValue={sel.value}
                key={idx}
                onChange={() => onChange('aggregationMethod', sel.id)}
              />
            );
          })}
        </UIButtonDropdownGroup>
      </UIInputWrapper>
      <UIInputWrapper title="Beräkningsgrupp">
        <UIButtonDropdownGroup
          translate={false}
          value={template.calculationGroups.find((el) => el.id === group.calculationGroupId)?.name}
        >
          <UIItemDropdown
            value={null}
            displayValue="Välj"
            onChange={() => onChange('calculationGroupId', null)}
          />
          {template.calculationGroups.map((sel, idx) => {
            return (
              <UIItemDropdown
                value={sel.value}
                displayValue={sel.name}
                key={idx}
                onChange={() => onChange('calculationGroupId', sel.id)}
              />
            );
          })}
        </UIButtonDropdownGroup>
      </UIInputWrapper>
      <UIInputWrapper title="Statistikkod">
        <UIButtonDropdownGroup
          translate={false}
          value={allStatisticsCodes.find((sel) => sel.id === group.statisticsCode)?.value}
        >
          {allStatisticsCodes.map((sel, idx) => {
            return (
              <UIItemDropdown
                value={sel.value}
                displayValue={sel.value}
                key={idx}
                onChange={() => onChange('statisticsCode', sel.id)}
              />
            );
          })}
        </UIButtonDropdownGroup>
      </UIInputWrapper>
      <div className="py-4 flex justify-center">
        <UIButtonSubmit
          onSubmit={() =>
            setTabState({ ...tabState, groups: { ...tabState.groups, groupIdWithOpenQuestions: group.id } })
          }
          name="Hantera frågor"
          assessments={true}
          isIcon={false}
        />
      </div>
    </div>
  );
};

TemplateGroupPropertiesForm.propTypes = {
  index: PropTypes.number.isRequired,
};

export default TemplateGroupPropertiesForm;
