import React, { useState } from 'react';
import propTypes from 'prop-types';

import UIInput from '../../global/UICommon/UIInput';
import UIButtonCheckbox from '../../global/UIButtons/UIButtonCheckbox';
import UIInputWrapper from '../UIAdmin/UIInputWrapper';

const UserDetailsForm = ({ data, setData }) => {
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);

  return (
    <form autoComplete="off">
      <UIInputWrapper title="Användarnamn">
        <UIInput
          type="admin"
          value={data?.userName}
          disabled={true}
          onChange={(e) => setData({ ...data, userName: e })}
        />
      </UIInputWrapper>
      <UIInputWrapper title="ANVÄNDARE AKTIV">
        <UIButtonCheckbox
          checked={data?.active}
          setChecked={() => setData({ ...data, active: !data?.active })}
          isDisabled={false}
          styleDesc={'text-sm md:text-xs'}
        />
      </UIInputWrapper>
      <div className="flex flex-col">
        <UIInputWrapper title="Sätt lösenord">
          <UIInput
            type="password"
            isPasswordHidden={isPasswordHidden}
            value={data?.newPassword ? data?.newPassword : ''}
            onChange={(e) => setData({ ...data, newPassword: e ? e : null })}
          />
        </UIInputWrapper>
        {data?.newPassword && (
          <div
            onClick={() => setIsPasswordHidden(!isPasswordHidden)}
            className="w-2/3 self-end text-start mt-1 pl-3 cursor-pointer"
          >
            <p className="underline">{isPasswordHidden ? 'Visa lösenord' : 'Dölj lösenord'}</p>
          </div>
        )}
      </div>
      <UIInputWrapper title="Återställ tvåfaktorautentisering">
        <UIButtonCheckbox
          checked={data?.resetTwoFactorAuth ? data?.resetTwoFactorAuth : false}
          setChecked={() => setData({ ...data, resetTwoFactorAuth: !data?.resetTwoFactorAuth })}
          isDisabled={false}
          styleDesc={'text-sm md:text-xs'}
        />
      </UIInputWrapper>
    </form>
  );
};

UserDetailsForm.propTypes = {
  data: propTypes.object,
  setData: propTypes.func,
};

export default UserDetailsForm;
