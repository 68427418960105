import query from '../useQueryWrapper';
import actions from 'api/businessType';

const useFetchResourceGroups = () => {
  return query.useQueryWrapper(
    'resourceGroups',
    async () => {
      const resourceGroups = await actions.fetchResourceGroups();
      return resourceGroups;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchResourceGroups;
