import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { AiFillCloseCircle } from 'react-icons/ai';

import AssessmentHistoryOptionsModal from '../AssessmentHistoryPage/AssessmentHistoryOptionsModal';

const AssessmentDashboardItemMenu = ({ type, handleClick, element, alignBottom }) => {
  const { t } = useTranslation();
  const [isOptionsModalVisible, setIsOptionsModalVisible] = useState(false);

  const modalItems = [
    {
      text: `${t('AssessmentDashboardPage.startedAssessment.continueText')}`,
      action: 'continue',
      isVisible: true,
    },
    {
      text: `${t('AssessmentHistoryPage.actionModal.goToPerson')}`,
      action: 'goToPerson',
      isVisible: element?.forPerson != null && Object.keys(element?.forPerson).length > 0 ? true : false,
    },
    {
      text: `${t('AssessmentDashboardPage.startedAssessment.deleteText')}`,
      action: 'deleteIncomplete',
      isVisible: true,
    },
  ];

  return (
    <div className="relative">
      <div className="my-auto">
        {!isOptionsModalVisible && (
          <BsThreeDotsVertical
            onClick={() => setIsOptionsModalVisible(true)}
            className={`text-[20px] ml-auto cursor-pointer`}
          />
        )}
        {isOptionsModalVisible && (
          <AiFillCloseCircle
            onClick={() => setIsOptionsModalVisible(false)}
            className={`text-[20px] ml-auto cursor-pointer`}
          />
        )}
      </div>
      <div className={`absolute w-[200px] right-6 rounded-xl
      ${alignBottom ? 'bottom-0' : 'top-0'}
        `}>
        <AssessmentHistoryOptionsModal
          isVisible={isOptionsModalVisible}
          element={element}
          handleClick={handleClick}
          data={type === 'started' && modalItems}
          onOutsideClick={() => setIsOptionsModalVisible(false)}
        />
      </div>
    </div>
  );
};

AssessmentDashboardItemMenu.propTypes = {
  type: PropTypes.oneOf(['started', 'history']),
  handleClick: PropTypes.func.isRequired,
  element: PropTypes.object,
  alignBottom: PropTypes.bool,
};

export default AssessmentDashboardItemMenu;
