import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

import { searchQuery } from 'state/global/searchQuery';
import useFetchIcfCodes from 'hooks/api/businessType/useFetchIcfCodes';
import useEditIcfCode from 'hooks/api/businessType/useEditIcfCode';

import tableElements from 'utils/KubenAdmin/IcfCodesPage/tableElements';
import icfCodesTableMenu from 'utils/KubenAdmin/IcfCodesPage/icfCodesTableMenu';

import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';
import UIInput from 'components/global/UICommon/UIInput';
import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';

import UICardWrapper from 'components/KubenAdmin/UIAdmin/UICardWrapper';
import UITitle from 'components/KubenAdmin/UIAdmin/UITitle';
import UITable from 'components/KubenAdmin/UITable/UITable';
import UITableElementWrapper from 'components/KubenAdmin/UITable/UITableElementWrapper';
import UITableElement from 'components/KubenAdmin/UITable/UITableElement';
import UITableMenu from 'components/KubenAdmin/UITable/UITableMenu';
import UIAddElementModal from 'components/KubenAdmin/UIAdmin/UIAddElementModal';

const IcfCodesPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [search, setSearch] = useRecoilState(searchQuery);
  const [selected, setSelected] = useState(null);
  const [isCreateIcfCode, setCreateIcfCode] = useState(false);

  const data = {
    id: uuidv4(),
    name: '',
    code: null,
    description: '',
    services: [],
  };

  const { data: icfCodesData, isLoading: isIcfCodesLoading, isError: isIcfCodesError, refetch } = useFetchIcfCodes();
  const {
    isLoading: isEditIcfCodeLoading,
    isError: isEditIcfCodeError,
    mutate: mutateEditIcfCode,
  } = useEditIcfCode(data);

  const filterBySearch = (search, arrayOfData) => {
    if (search.length === 0) {
      return arrayOfData;
    }

    return arrayOfData.filter((el) => {
      const lowerCaseSearch = search.toLowerCase();
      return el.name.toLowerCase().includes(lowerCaseSearch) || el.code.toLowerCase().includes(lowerCaseSearch);
    });
  };

  const filteredData = filterBySearch(search, icfCodesData);

  const handleClick = (type, id) => {
    if (type == 'edit') {
      history.push('/admin/icf-codes/' + id);
    } else if (type == 'create') {
      setCreateIcfCode(true);
    }
  };

  const onCreateIcfCode = (code) => {
    data.code = code;
    mutateEditIcfCode(data, {
      onSuccess: () => history.push('/admin/icf-codes/' + data.id),
    });
  };

  useEffect(() => {
    refetch();
  }, [icfCodesData]);

  if (isIcfCodesLoading || isEditIcfCodeLoading) {
    return <UILoadingModal />;
  }

  if (isIcfCodesError || isEditIcfCodeError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <UIStaticComponentsWrapper isAdmin={true}>
      <UITitle
        title="ICF-KODER"
        isButton={true}
        buttonName="Skapa ICF-kod"
        onClick={() => handleClick('create')}
      />
      <UICardWrapper title="Filtrera bland ICF-koder">
        <UIInput
          type="search"
          placeholder="Sök"
          value={search}
          onChange={(text) => setSearch(text)}
        />
      </UICardWrapper>
      {isCreateIcfCode && (
        <UIAddElementModal
          isOpen={true}
          onClose={() => setCreateIcfCode(false)}
          inputPlaceholder="Kod"
          modalTitle="Skapa ny ICF-kod"
          onSave={(text) => onCreateIcfCode(text)}
        />
      )}
      <UITable tableElements={tableElements}>
        {filteredData.length > 0 ? (
          filteredData.map((el, idx) => {
            return (
              <UITableElementWrapper key={idx}>
                <UITableElement
                  name={el.code}
                  maxLength={100}
                />
                <UITableElement
                  name={el.name}
                  maxLength={100}
                />
                <UITableMenu
                  handleClick={(type) => handleClick(type, el.id)}
                  isOpen={selected?.id === el.id}
                  setOpen={() => setSelected(selected?.id === el.id ? null : el)}
                  data={icfCodesTableMenu}
                />
              </UITableElementWrapper>
            );
          })
        ) : (
          <div className="py-8">Inga värden att visa</div>
        )}
      </UITable>
    </UIStaticComponentsWrapper>
  );
};

export default IcfCodesPage;
