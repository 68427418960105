import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/businessType';
import { businessTypesForRegion as businessTypesForRegionRecoil } from 'state/global/businessTypes';

const useFetchBusinessTypesForRegion = (id) => {
  const setBusinessTypesForRegion = useSetRecoilState(businessTypesForRegionRecoil);

  return query.useQueryWrapper(
    'businessTypesForRegion',
    async () => {
      const businessTypesForRegion = await actions.fetchBusinessTypesForRegion(id);
      setBusinessTypesForRegion(businessTypesForRegion);
      return businessTypesForRegion;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchBusinessTypesForRegion;
