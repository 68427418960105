import addTitleToObjectsInArray from '../SortableTree/addTitleToObjectsInArray';

const removeTitleFromObjectsInArray = (arr) => {
  arr.forEach((obj) => {
    delete obj.title;
    if (obj.children) {
      removeTitleFromObjectsInArray(obj.children);
    }
  });
};

const checkIsInteractionNeeded = (unitVersions, parentVersions) => {
  for (const unit of unitVersions) {
    for (const version of parentVersions) {
      if (
        (version.endDate === null || unit.startDate <= version.endDate) &&
        (unit.endDate === null || unit.endDate >= version.startDate)
      ) {
        if (unit.unitTypeId === null) return true;
        if (unit.nightStaffingId === null && version.nightStaffingIds.length != 0) return true;
        if (unit.unitTypeId != null && version.unitTypeIds.length === 0) return true;
        if (unit.nightStaffingId != null && version.nightStaffingIds.length === 0) return true;
        if (unit.unitTypeId != null && !version.unitTypeIds.some((unitType) => unitType === unit.unitTypeId))
          return true;
        if (
          unit.nightStaffingId != null &&
          !version.nightStaffingIds.some((nightStaffing) => nightStaffing === unit.nightStaffingId)
        )
          return true;
        return false;
      }
    }
  }
  return true;
};

const searchInChildren = (array, parent, businessTypes) => {
  array.forEach((obj) => {
    if (obj.$type === 'unit') {
      const isInteractionNeeded = checkIsInteractionNeeded(obj.referenceDataVersions, parent.businessTypeVersions);
      obj.interactionNeeded = isInteractionNeeded;
      parent.interactionNeeded = isInteractionNeeded;
    }
    if (obj.$type === 'organizationUnit') {
      const businessType = businessTypes.find((el) => el.id === obj.businessTypeId);
      obj.businessTypeVersions = businessType.referenceDataVersions;

      if (obj.children != null && obj.children.length > 0) {
        searchInChildren(obj.children, obj, businessTypes);
      }
    }
  });
};

export const highlightElements = (array, setArray, businessTypes) => {
  const tmpRegionDetailsData = JSON.parse(JSON.stringify(array));
  tmpRegionDetailsData.children.forEach((obj) => {
    if (obj.$type === 'organizationUnit') {
      obj.interactionNeeded = false;

      const businessType = businessTypes.find((el) => el.id === obj.businessTypeId);
      obj.businessTypeVersions = businessType.referenceDataVersions;

      if (obj.children) {
        searchInChildren(obj.children, obj, businessTypes);
      }

      if (obj.children.length === 0) {
        obj.interactionNeeded = false;
      }
    }
  });
  setArray(tmpRegionDetailsData);
};

/* eslint-disable require-jsdoc */
export const modifyDataForSortableTree = (array, setNewProperty) => {
  const tmpRegionDetailsData = JSON.parse(JSON.stringify(array.children));
  const modifyedArray = {
    ...array,
    children: tmpRegionDetailsData,
  };
  addTitleToObjectsInArray(tmpRegionDetailsData);
  setNewProperty(modifyedArray);
};

export const deleteTitleFromModifiedData = async (array, setNewProperty) => {
  const tmpRegionDetailsData = await JSON.parse(JSON.stringify(array.children));
  removeTitleFromObjectsInArray(tmpRegionDetailsData);
  await setNewProperty({ ...array, children: tmpRegionDetailsData });
};
