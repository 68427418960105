import { atom } from 'recoil';

export const businessType = atom({
  key: 'businessType',
  default: {},
});

export const businessTypeComparison = atom({
  key: 'businessTypeComparison',
  default: {},
});

export const businessTypeForRegion = atom({
  key: 'businessTypeForRegion',
  default: {},
});

export const businessTypeForRegionComparison = atom({
  key: 'businessTypeForRegionComparison',
  default: {},
});
