import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import UIInput from '../../global/UICommon/UIInput';
import SearchPageSectionElement from './SearchPageSectionElement';

const SearchPageTextInput = ({ searchText = '', setSearchText, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full">
      <div>
        <SearchPageSectionElement headerText={t('SearchPage.searchByText')}>
          <div className="w-full h-11">
            <UIInput
              type="search"
              placeholder={t('ChooseTemplatePage.searchPlaceholder')}
              value={searchText}
              onChange={setSearchText}
              onSubmit={onSubmit}
            />
          </div>
        </SearchPageSectionElement>
      </div>
    </div>
  );
};

SearchPageTextInput.propTypes = {
  searchText: propTypes.string.isRequired,
  setSearchText: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
};

export default SearchPageTextInput;
