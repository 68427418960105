import React, { useEffect, useRef, useState } from 'react';
import { WidthProvider as widthProvider, Responsive } from 'react-grid-layout';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { graphFilters, graphPeriodFilter } from 'state/KubenDashboard/graphFilters';
import {
  droppableGraphDefinitionItem,
  editGraphLayout,
  graphDefinitions,
  graphDrillDownData,
  graphLayout,
} from 'state/KubenDashboard/graphs';
import { currentUser, currentUserRegion } from 'state/global/currentUser';
import useSaveUserProfile from 'hooks/api/user/useSaveUserProfile';
import useMutateFetchDashboardGraphs from 'hooks/api/graph/useMutateFetchDashboardGraphs';
import { format } from 'date-fns';

import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import DashboardChartWrapper from 'components/KubenDashboard/Charts/DashboardChartWrapper';
import DashboardPinning from 'components/KubenDashboard/Charts/DashboardPinning';
import DashboardPreviewChartWrapper from 'components/KubenDashboard/Charts/DashboardPreviewChartWrapper';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const ResponsiveReactGridLayout = widthProvider(Responsive);

const DashboardPage = () => {
  const user = useRecoilValue(currentUser);
  const setLayoutRef = useSetRecoilState(graphLayout);
  const [hasLayoutChanged, setLayoutChanged] = useState(false);
  const [layout, setLayout] = useState(undefined);
  const [previousLayout, setPreviousLayout] = useState(undefined);
  const [isEditLayout, setIsEditLayout] = useRecoilState(editGraphLayout);
  const gridRef = useRef();
  const allGraphDefinitions = useRecoilValue(graphDefinitions);
  const chosenGraphRegion = useRecoilValue(currentUserRegion);
  const chosenGraphFilters = useRecoilValue(graphFilters);
  const chosenGraphPeriod = useRecoilValue(graphPeriodFilter);
  const droppableGraphDefinition = useRecoilValue(droppableGraphDefinitionItem);
  const resetDrillDown = useResetRecoilState(graphDrillDownData);
  const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');
  const {
    data: dashboardGraphs,
    isError: isDashboardGraphsError,
    mutate: mutateFetchDashboardGraphs,
  } = useMutateFetchDashboardGraphs(
    chosenGraphRegion?.id,
    chosenGraphFilters.regionNodes,
    chosenGraphFilters.businessTypes,
    format(chosenGraphPeriod.startDate, 'yyyy-MM-dd'),
    format(chosenGraphPeriod.endDate, 'yyyy-MM-dd'),
  );

  useEffect(() => {
    resetDrillDown();
  }, []);

  useEffect(() => {
    if (chosenGraphRegion?.id) {
      mutateFetchDashboardGraphs();
    }
  }, [chosenGraphRegion?.id, chosenGraphFilters, chosenGraphPeriod]);

  const {
    isLoading: isSaveUserProfileLoading,
    mutate: mutateSaveUserProfile,
    isSuccess: isSaveProfileSuccess,
    isError: isSaveUserProfileError,
  } = useSaveUserProfile(
    null,
    layout?.map((item) => ({ x: item.x, y: item.y, h: item.h, w: item.w, graphId: item.i })),
  );

  useEffect(() => {
    if (user?.userProfile) {
      const userLayout = user.userProfile.pinnedDashboardComponents.map((item) => ({
        x: item.x,
        y: item.y,
        h: item.h,
        w: item.w,
        i: item.graphId,
        static: item.static,
      }));
      setLayout(userLayout);
      setLayoutRef(userLayout);
    }
  }, [user]);

  useEffect(() => {
    if (isSaveProfileSuccess) {
      setIsEditLayout(false);
    }
  }, [isSaveProfileSuccess]);

  const saveLayout = () => {
    setLayoutChanged(false);
    setPreviousLayout(layout);
    mutateSaveUserProfile();
  };

  const startEditLayout = () => {
    if (!isEditLayout) {
      setLayoutChanged(false);
      setPreviousLayout(layout);
      setIsEditLayout(true);
    }
  };

  const cancelEditLayout = () => {
    setLayout(previousLayout);
    setLayoutRef(previousLayout);
    setIsEditLayout(false);
  };

  const onDrop = (layout, layoutItem, _event) => {
    const modifiedItem = { ...layoutItem };
    modifiedItem.i = droppableGraphDefinition.graphId;
    /*
      check if new item is within bounds
    */
    if (Object.keys(modifiedItem).length > 1) {
      const modifiedLayout = [...layout, modifiedItem];
      setLayoutRef(modifiedLayout);
      onLayoutChange(modifiedLayout);
    }
  };

  const onRemove = (graphId) => {
    const modifiedLayout = [...layout];
    const deleteIndex = modifiedLayout.findIndex((el) => el.i === graphId);
    modifiedLayout.splice(deleteIndex, 1);
    setLayoutRef(modifiedLayout);

    setTimeout(() => {
      onLayoutChange(modifiedLayout);
    }, 50);
  };

  const onLayoutChange = (layout) => {
    if (!isEditLayout) {
      return;
    }

    setLayout(layout);
    setLayoutChanged(true);
  };

  const canModifyLayout = () => {
    return isEditLayout && !isSaveUserProfileLoading;
  };

  if (isDashboardGraphsError) {
    return <UIErrorModal />;
  }

  return (
    <div className="w-full h-full pt-12">
      <div
        className="w-full flex space-x-5
        h-24 2xl:h-28 3xl:h-32"
      >
        {dashboardGraphs ? (
          dashboardGraphs.staticGraphs.map((graph) => (
            <DashboardPreviewChartWrapper
              key={graph.id}
              graphDefinition={allGraphDefinitions.find((g) => g.graphId == graph.id)}
              chartId={graph.id}
              isEditLayout={isEditLayout}
              chosenGraphRegion={chosenGraphRegion}
              chosenGraphFilters={chosenGraphFilters}
              chosenGraphPeriod={chosenGraphPeriod}
              data={graph}
            />
          ))
        ) : (
          <div className="w-full h-full bg-white border border-dashboard-border rounded-xl animate-pulse" />
        )}
      </div>
      <ResponsiveReactGridLayout
        rowHeight={{ xxs: 18, xs: 18, sm: 20, md: 22, lg: 24, xl: 32 }[currentBreakpoint]}
        cols={{ xxs: 16, xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}
        breakpoints={{ xxs: 0, xs: 480, sm: 768, md: 996, lg: 1200, xl: 1600 }}
        onBreakpointChange={(breakpoint) => setCurrentBreakpoint(breakpoint)}
        autoSize={true}
        draggableHandle=".dragMe"
        margin={[20, 20]}
        containerPadding={[0, 20]}
        layout={layout}
        isDraggable={canModifyLayout()}
        isResizable={canModifyLayout()}
        isDroppable={canModifyLayout()}
        isBounded={false}
        useCSSTransforms={false}
        droppingItem={{ i: 'tempId', h: 8, w: 8 }}
        resizeHandles={['n', 'e', 's', 'w', 'ne', 'se', 'nw', 'sw']}
        onLayoutChange={onLayoutChange}
        onDrop={onDrop}
        ref={gridRef}
      >
        {layout
          ?.filter((d) => d.i !== 'tempId')
          .map((layout) => (
            <div
              key={layout.i}
              data-grid={{
                x: layout.x,
                y: layout.y,
                w: layout.w,
                h: layout.h,
                minW: 5,
                minH: 6,
              }}
            >
              <DashboardChartWrapper
                graphDefinition={allGraphDefinitions.find((g) => g.graphId == layout.i)}
                chartId={layout.i}
                isEditLayout={isEditLayout}
                chosenGraphRegion={chosenGraphRegion}
                chosenGraphFilters={chosenGraphFilters}
                chosenGraphPeriod={chosenGraphPeriod}
                data={dashboardGraphs?.dynamicGraphs.find((g) => g.id == layout.i)}
                useIncomingData={!isEditLayout}
                onRemove={() => onRemove(layout.i)}
              />
            </div>
          ))}
      </ResponsiveReactGridLayout>
      <DashboardPinning
        onSave={saveLayout}
        onCancel={cancelEditLayout}
        onStart={startEditLayout}
        hasChanged={hasLayoutChanged}
        isLoading={isSaveUserProfileLoading}
        isError={isSaveUserProfileError}
      />
    </div>
  );
};

export default DashboardPage;
