import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/businessType';
import { unitTypes as unitTypesRecoil } from 'state/global/businessTypes';

const useFetchUnitTypes = () => {
  const setUnitTypes = useSetRecoilState(unitTypesRecoil);

  return query.useQueryWrapper(
    'fetchUnitTypes',
    async () => {
      const unitTypes = await actions.fetchUnitTypes();
      setUnitTypes(unitTypes);
      return unitTypes;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchUnitTypes;
