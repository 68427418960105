import { useTranslation } from 'react-i18next';

const getAssessmentHistoryTableLabels = (assessmentsType) => {
  const { t } = useTranslation();

  const personAssessmentHistoryTableLabels = [
    {
      text: `${t('AssessmentHistoryPage.table.templateLabel')}`,
      space: '2/12 md:w-[27%]',
    },
    {
      text: `${t('AssessmentHistoryPage.table.executorLabel')}`,
      space: '2/12 md:w-[23%]',
    },
    {
      text: `${t('AssessmentHistoryPage.table.assessmentDateLabel')}`,
      space: '2/12 md:w-[23%]',
    },
    {
      text: `${t('AssessmentHistoryPage.table.startStopDateLabel')}`,
      space: '2/12 md:w-[27%]',
    },
    {
      text: `${t('AssessmentHistoryPage.table.bedLabel')}`,
      space: '1/12 md:hidden',
    },
    {
      text: `${t('AssessmentHistoryPage.table.notLabel')}`,
      space: '1/12 md:hidden',
    },
    {
      text: ``,
      space: '1/12 md:w-[5%]',
    },
  ];

  const searchedAssessmentsTableLabels = [
    {
      text: `${t('AssessmentHistoryPage.table.executorLabel')}`,
      space: '2/12 md:w-[20%]',
    },
    {
      text: `${t('AssessmentHistoryPage.table.templateLabel')}`,
      space: '2/12 md:w-[20%]',
    },
    {
      text: `${t('AssessmentHistoryPage.table.userLabel')}`,
      space: '2/12 md:w-[20%]',
    },
    {
      text: `${t('AssessmentHistoryPage.table.assessmentDateLabel')}`,
      space: '2/12 md:w-[17%]',
    },
    {
      text: `${t('AssessmentHistoryPage.table.startStopDateLabel')}`,
      space: '2/12 md:w-[17%]',
    },
    {
      text: `${t('AssessmentHistoryPage.table.bedLabel')}`,
      space: '1/12 md:hidden',
    },
    {
      text: ``,
      space: '1/12 md:w-[5%]',
    },
  ];

  const assessmentHistoryInitOrCompletedTableLabels = [
    {
      text: `ID`,
      space: '2/12 md:hidden',
    },
    {
      text: `${t('AssessmentDashboardPage.tableItems.template')}`,
      space: '2/12 md:w-[27%]',
    },
    {
      text: `${t('AssessmentDashboardPage.tableItems.unit')}`,
      space: '2/12 md:w-[22%]',
    },
    {
      text: `${t('AssessmentDashboardPage.tableItems.date')}`,
      space: '2/12 md:w-[22%]',
    },
    {
      text: `${t('AssessmentHistoryPage.table.startStopDateLabel')}`,
      space: '2/12 md:w-[27%]',
    },
    {
      text: ``,
      space: '1/12 md:w-[5%]',
    },
  ];

  if (assessmentsType === 'personAssessments') {
    return personAssessmentHistoryTableLabels;
  } else if (assessmentsType === 'initialAssessments' || assessmentsType === 'completedAssessments'
     || assessmentsType === 'completedAllAssessments') {
    return assessmentHistoryInitOrCompletedTableLabels;
  } else if (assessmentsType === 'searchedAssessments') {
    return searchedAssessmentsTableLabels;
  }
};

export default getAssessmentHistoryTableLabels;
