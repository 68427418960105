import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import useFetchBusinessTypes from 'hooks/api/businessType/useFetchBusinessTypes';
import useFetchResourceGroups from 'hooks/api/businessType/useFetchResourceGroups';

import { templateFormState } from 'state/KubenAdmin/templates';

import UIButtonCheckbox from '../../global/UIButtons/UIButtonCheckbox';
import UIButtonDropdownGroup from '../../global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from '../../global/UIButtons/UIItemDropdown';
import UISpinner from 'components/global/UICommon/UISpinner';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';

import UIInputWrapper from '../UIAdmin/UIInputWrapper';

import TemplateInput from './TemplateInput';

const TemplateCalculationResultPropertiesForm = ({ index }) => {
  const { t } = useTranslation();
  const [template, setTemplate] = useRecoilState(templateFormState);
  const calculationResult = template.calculationResults[index];

  const {
    data: businessTypes,
    isLoading: isBusinessTypesLoading,
    isError: isBusinessTypesError,
  } = useFetchBusinessTypes();
  const {
    data: resourceGroups,
    isLoading: isResourceGroupsLoading,
    isError: isResourceGroupsError,
  } = useFetchResourceGroups();

  const filteredResourceGroups = resourceGroups?.filter((resourceGroup) =>
    businessTypes
      .find((businessType) => businessType.id === calculationResult.businessTypeId)
      ?.resourceGroupIds.includes(resourceGroup.id),
  );

  const onChange = (name, value) => {
    const tmpTemplate = { ...template };
    const tmpCalculationResults = [...tmpTemplate.calculationResults];
    const tmpCalculationResult = { ...tmpCalculationResults[index] };

    tmpCalculationResult[name] = value;
    tmpCalculationResults[index] = tmpCalculationResult;

    tmpTemplate.calculationResults = tmpCalculationResults;
    setTemplate(tmpTemplate);
  };

  if (isBusinessTypesLoading || isResourceGroupsLoading) {
    return (
      <div className="w-full h-20">
        <UISpinner theme="fast" />
      </div>
    );
  }

  if (isBusinessTypesError || isResourceGroupsError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <div>
      <TemplateInput
        title="Id"
        disabled={true}
        value={calculationResult.id}
      />
      <TemplateInput
        title="Namn"
        value={calculationResult.name}
        onChange={(e) => onChange('name', e.target.value)}
      />
      <TemplateInput
        title="Summeringstext"
        value={calculationResult.summaryText}
        onChange={(e) => onChange('summaryText', e.target.value)}
      />
      <UIInputWrapper title="Bara synlig för admin">
        <UIButtonCheckbox
          setChecked={(checked) => onChange('adminOnly', checked)}
          checked={calculationResult.adminOnly}
        />
      </UIInputWrapper>
      <UIInputWrapper title="Resultat är nivåbaserat">
        <UIButtonCheckbox
          setChecked={(checked) => onChange('staffingIsLevelBased', checked)}
          checked={calculationResult.staffingIsLevelBased}
        />
      </UIInputWrapper>
      <UIInputWrapper title="Genererar resursbehov">
        <UIButtonCheckbox
          setChecked={(checked) => onChange('generateResourceNeeds', checked)}
          checked={calculationResult.generateResourceNeeds}
        />
      </UIInputWrapper>
      <UIInputWrapper title="Insatstyp">
        <UIButtonDropdownGroup
          translate={false}
          isReadonly={true}
          value={businessTypes.find((el) => el.id === calculationResult.businessTypeId)?.name}
          absolute="absolute"
          zIndex="z-20"
        />
      </UIInputWrapper>
      <UIInputWrapper title="Resurstyp">
        <UIButtonDropdownGroup
          translate={false}
          value={filteredResourceGroups.find((el) => el.id === calculationResult.resourceGroupId)?.value}
          absolute="absolute"
          zIndex="z-20"
        >
          {filteredResourceGroups.map((sel, idx) => (
            <UIItemDropdown
              key={idx}
              value={sel.value}
              onChange={() => onChange('resourceGroupId', sel.id)}
              displayValue={sel.value}
            />
          ))}
        </UIButtonDropdownGroup>
      </UIInputWrapper>
      <UIInputWrapper title="Beräkningsgrupp">
        <UIButtonDropdownGroup
          translate={false}
          value={template.calculationGroups.find((el) => el.id === calculationResult.pointSource)?.name}
        >
          {template.calculationGroups.map((sel, idx) => {
            return (
              <UIItemDropdown
                value={sel.value}
                displayValue={sel.name}
                key={idx}
                onChange={() => onChange('pointSource', sel.id)}
              />
            );
          })}
        </UIButtonDropdownGroup>
      </UIInputWrapper>
      <TemplateInput
        title="Formula"
        value={calculationResult.formula}
        onChange={(e) => onChange('formula', e.target.value)}
      />
    </div>
  );
};

TemplateCalculationResultPropertiesForm.propTypes = {
  index: PropTypes.number.isRequired,
};

export default TemplateCalculationResultPropertiesForm;
