import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useFetchNightStaffings from 'hooks/api/businessType/useFetchNightStaffings';
import useFetchUnitTypes from 'hooks/api/businessType/useFetchUnitTypes';

import UIButtonDropdownGroup from 'components/global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from 'components/global/UIButtons/UIItemDropdown';
import UIButtonSubmit from 'components/global/UIButtons/UIButtonSubmit';
import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIInput from 'components/global/UICommon/UIInput';

import UIInputWrapper from 'components/KubenAdmin/UIAdmin/UIInputWrapper';
import UIFoldableCard from '../UIAdmin/UIFoldableCard';

const BusinessValuesStairVersionsForm = ({
  setElement,
  referenceVersions,
  element,
  staffings,
  staffingsNight,
  levels,
  unitTypes,
  nightStaffings,
  onEditVersion,
  isReadonly,
  onOpenAddNewVersionModal,
  onChangeVersion,
}) => {
  const { t } = useTranslation();

  const {
    data: allNightStaffings,
    isLoading: isNightStaffingsLoading,
    isError: isNightStaffingsError,
  } = useFetchNightStaffings();
  const { data: allUnitTypes, isLoading: isUnitTypesLoading, isError: isUnitTypesError } = useFetchUnitTypes();

  if (isNightStaffingsLoading || isUnitTypesLoading) {
    return <UILoadingModal />;
  }

  if (isNightStaffingsError || isUnitTypesError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <>
      {(staffings.length !== 0 || staffingsNight?.length !== 0) && (
        <div className="flex flex-col justify-center space-y-4">
          <div className="flex justify-end space-x-6">
            <UIButtonSubmit
              name="Redigera befintlig version"
              variant="bg-app-assessment"
              onSubmit={onEditVersion}
              assessments={true}
              isIcon={false}
              fixedWidth={false}
            />
            <UIButtonSubmit
              name="Skapa ny version"
              variant="bg-app-assessment"
              onSubmit={onOpenAddNewVersionModal}
              assessments={true}
              isIcon={false}
              fixedWidth={false}
            />
          </div>
          <div className="flex justify-end items-center">
            {referenceVersions.length > 1 && (
              <div className="flex items-center text-sm">
                <p className="font-semibold mr-3">Historik</p>
                <UIButtonDropdownGroup
                  value={
                    (element.startDate != null ? element.startDate : 'Början') +
                    (element.endDate != null ? ` - ${element.endDate}` : ' (Nuvarande period)')
                  }
                  width="w-80"
                  absolute="absolute"
                  zIndex="z-30"
                  translate={false}
                  style="white"
                >
                  {referenceVersions.map((version, index) => (
                    <UIItemDropdown
                      value={version.startDate}
                      displayValue={
                        (version.startDate != null ? version.startDate : 'Början') +
                        (version.endDate != null ? ` - ${version.endDate}` : ' (Nuvarande period)')
                      }
                      onChange={() => onChangeVersion(version.startDate)}
                      key={index}
                    />
                  ))}
                </UIButtonDropdownGroup>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="text-left font-semibold md:text-lg text-xl title pb-3">Bemanning</div>
      {staffings.length == 0 && (
        <div className="md:text-lg text-md">{t('BusinessValuesPage.missingStaffingstaircaseText')}</div>
      )}
      {staffings.map((staffing, staffingIndex) => (
        <div
          key={staffing.id}
          className="pb-4"
        >
          <UIFoldableCard
            key={staffing.id}
            title={staffing.key}
            defaultOpen={false}
          >
            {unitTypes.map((unitType) =>
              levels.map((level) => {
                const employees = staffing.staffingByUnitTypeAndLevel.find(
                  (el) => el.level === level.level && el.unitTypeId === unitType,
                );

                return (
                  <UIInputWrapper
                    key={`${unitType}-${level.level}`}
                    title={`${allUnitTypes.find((el) => el.id === unitType)?.name || ''} - ${level.name}`}
                  >
                    <UIInput
                      id={`fullTimeEmployees-${unitType}-${level.level}`}
                      name={`fullTimeEmployees-${unitType}-${level.name}`}
                      onChange={(e) => {
                        const updatedStaffings = staffings.map((updatedStaffing, updatedStaffingIndex) => {
                          if (updatedStaffingIndex === staffingIndex) {
                            return {
                              ...updatedStaffing,
                              staffingByUnitTypeAndLevel: staffing.staffingByUnitTypeAndLevel.map((staff) => {
                                if (staff.level === level.level && staff.unitTypeId === unitType) {
                                  return { ...staff, fullTimeEmployees: Number(e.target.value) };
                                }
                                return staff;
                              }),
                            };
                          }
                          return updatedStaffing;
                        });

                        setElement((prev) => ({ ...prev, staffings: updatedStaffings }));
                      }}
                      value={employees?.fullTimeEmployees}
                      disabled={isReadonly}
                      type="number"
                    />
                  </UIInputWrapper>
                );
              }),
            )}
          </UIFoldableCard>
        </div>
      ))}
      <div className="text-left font-semibold md:text-lg text-xl title pb-3 pt-6">Nattbemanning</div>
      {staffingsNight?.length == 0 && (
        <div className="md:text-lg text-md">{t('BusinessValuesPage.missingNightStaffingStaircaseText')}</div>
      )}
      {staffingsNight?.length != 0 && (
        <UIFoldableCard
          title="Default"
          defaultOpen={false}
        >
          {unitTypes.map((unitType) =>
            nightStaffings.map((nightStaffing) => {
              const employees = staffingsNight?.find(
                (el) => el.nightStaffingId === nightStaffing && el.unitTypeId === unitType,
              );

              return (
                <UIInputWrapper
                  key={`${unitType}-${nightStaffing}`}
                  title={`${allUnitTypes.find((el) => el.id === unitType)?.name || ''} - ${
                    allNightStaffings.find((el) => el.id === nightStaffing)?.name || ''
                  }`}
                >
                  <UIInput
                    id={`fullTimeEmployees-${unitType}-${nightStaffing}`}
                    name={`fullTimeEmployees-${unitType}-${nightStaffing}`}
                    onChange={(e) => {
                      const updatedNightstaffings = staffingsNight?.map((staffingNight) => {
                        if (staffingNight.nightStaffingId === nightStaffing && staffingNight.unitTypeId === unitType) {
                          return { ...staffingNight, fullTimeEmployees: Number(e.target.value) };
                        }
                        return staffingNight;
                      });

                      setElement((prev) => ({ ...prev, staffingByUnitTypeAndNightStaffings: updatedNightstaffings }));
                    }}
                    value={employees?.fullTimeEmployees}
                    disabled={isReadonly}
                    type="number"
                  />
                </UIInputWrapper>
              );
            }),
          )}
        </UIFoldableCard>
      )}
    </>
  );
};

BusinessValuesStairVersionsForm.propTypes = {
  setElement: propTypes.func.isRequired,
  element: propTypes.any.isRequired,
  staffings: propTypes.array.isRequired,
  staffingsNight: propTypes.array.isRequired,
  levels: propTypes.array.isRequired,
  unitTypes: propTypes.array.isRequired,
  nightStaffings: propTypes.array.isRequired,
  onEditVersion: propTypes.func.isRequired,
  isReadonly: propTypes.bool.isRequired,
  onOpenAddNewVersionModal: propTypes.func.isRequired,
  referenceVersions: propTypes.array.isRequired,
  onChangeVersion: propTypes.func.isRequired,
};

export default BusinessValuesStairVersionsForm;
