import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import useFetchIcfCode from 'hooks/api/businessType/useFetchIcfCode';
import useDeleteIcfCode from 'hooks/api/businessType/useDeleteIcfCode';
import useEditIcfCode from 'hooks/api/businessType/useEditIcfCode';

import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';
import UIInput from 'components/global/UICommon/UIInput';
import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIBackButton from 'components/global/UIButtons/UIBackButton';
import UIButtonSubmit from 'components/global/UIButtons/UIButtonSubmit';

import UIModalWithoutSaveData from 'components/KubenAdmin/UIAdmin/UIModalWithoutSaveData';
import UITitle from 'components/KubenAdmin/UIAdmin/UITitle';
import UIFoldableCard from 'components/KubenAdmin/UIAdmin/UIFoldableCard';
import UIInputWrapper from 'components/KubenAdmin/UIAdmin/UIInputWrapper';
import UIConfirmModal from 'components/KubenAdmin/UIAdmin/UIConfirmModal';
import TemplateTextArea from 'components/KubenAdmin/TemplatesPage/TemplateTextArea';

const IcfCodesEditPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();

  const [isDelete, setDelete] = useState(false);

  const [icfCode, setIcfCode] = useState();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [services, setServices] = useState();
  const data = {
    id: id,
    name: name,
    code: icfCode,
    description: description,
    services: services,
  };

  const { data: icfCodeData, isLoading: isIcfCodesLoading, isError: isIcfCodesError } = useFetchIcfCode(id);
  const {
    isLoading: isEditIcfCodeLoading,
    isError: isEditIcfCodeError,
    mutate: mutateEditIcfCode,
  } = useEditIcfCode(data);
  const {
    isLoading: isDeleteIcfCodeLoading,
    isError: isDeleteIcfCodeError,
    mutate: mutateDeleteIcfCode,
    isSuccess: isDeleteIcfCodeSuccess,
  } = useDeleteIcfCode(id);

  const [originalData, setOriginalData] = useState(icfCodeData);
  const copyOfData = { ...data };

  const onSave = (closeOnSave) => {
    mutateEditIcfCode();
    setOriginalData(data);
    if (closeOnSave && !isEditIcfCodeLoading && !isEditIcfCodeError) {
      history.goBack();
    }
  };

  const onDelete = () => {
    setOriginalData(data);
    mutateDeleteIcfCode();
  };

  useEffect(() => {
    if (isDeleteIcfCodeSuccess) {
      history.push('/admin/icf-codes');
    }
  }, [isDeleteIcfCodeSuccess]);

  useEffect(() => {
    if (icfCodeData != null) {
      setName(icfCodeData?.name);
      setIcfCode(icfCodeData?.code);
      setDescription(icfCodeData?.description);
      setServices(icfCodeData?.services);
    }
  }, [icfCodeData]);

  if (isIcfCodesLoading || isEditIcfCodeLoading || isDeleteIcfCodeLoading) {
    return <UILoadingModal />;
  }

  if (isIcfCodesError || isEditIcfCodeError || isDeleteIcfCodeError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <>
      <UIStaticComponentsWrapper isAdmin={true}>
        <UIModalWithoutSaveData
          elementToCompare={copyOfData}
          orginalElement={originalData}
        />
        <div className="w-full">
          <div className="relative flex items-center pb-10">
            <div className="cursor-pointer flex items-center whitespace-nowrap">
              <UIBackButton />
            </div>
            <div className="absolute left-1/2 transform -translate-x-1/2">
              <UITitle title="ICF-kod:" />
            </div>
          </div>
          <UIFoldableCard
            title="Egenskaper"
            defaultOpen={true}
          >
            <UIInputWrapper title="ID">
              <UIInput
                disabled={true}
                value={id}
                type="text"
              />
            </UIInputWrapper>
            <UIInputWrapper title="ICF-kod">
              <UIInput
                value={icfCode}
                onChange={(e) => setIcfCode(e.target.value)}
                type="text"
              />
            </UIInputWrapper>
            <UIInputWrapper title="Namn">
              <UIInput
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
              />
            </UIInputWrapper>
            <TemplateTextArea
              title="Beskrivning"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </UIFoldableCard>
          <div className="w-full flex justify-center pt-8 space-x-5">
            <UIButtonSubmit
              name="Spara"
              assessments={true}
              isIcon={false}
              onSubmit={() => onSave(false)}
            />
            <UIButtonSubmit
              name="Spara och stäng"
              assessments={true}
              isIcon={false}
              onSubmit={() => onSave(true)}
            />
            <UIButtonSubmit
              name="Radera"
              assessments={true}
              isIcon={false}
              onSubmit={() => setDelete(true)}
            />
          </div>
          {isDelete && (
            <UIConfirmModal
              title={`Är du säker på att du vill radera ICF-koden "${icfCode}"?`}
              onCancel={() => setDelete(false)}
              onConfirm={onDelete}
            />
          )}
        </div>
      </UIStaticComponentsWrapper>
    </>
  );
};

export default IcfCodesEditPage;
