import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const AssessmentDashboardLinkCard = ({ link, icon, title, description, isDisabled}) => {
  return (
    <Link
      to={link}
      className={`flex flex-col relative w-full h-full
        transition-colors ease-out duration-300 border border-tertiary bg-white rounded-2xl
        space-y-3 text-left p-6 ${
          isDisabled ? 'pointer-events-none cursor-not-allowed opacity-[0.65] disabled' : 'cursor-pointer choice-card'
        }`}
    >
      <div className="p-2 rounded-lg background-primary w-fit text-white text-3xl mb-2">{icon}</div>
      <h2 className="font-normal text-lg lg:text-xl xl:text-2xl">{title}</h2>
      <p className="font-normal text-base">{description}</p>
    </Link>
  );
};

 AssessmentDashboardLinkCard.propTypes = {
  link: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

export default AssessmentDashboardLinkCard;
