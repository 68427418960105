import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import closeElementFunction from 'utils/global/closeElementFunction';
import { currentUser } from 'state/global/currentUser';

const AssessmentHistoryOptionsModal = ({
  isVisible,
  assessmentsType = '',
  handleClick,
  data,
  element,
  onOutsideClick,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const wrapperRef = useRef(null);
  const user = useRecoilValue(currentUser);
  closeElementFunction(wrapperRef, onOutsideClick);

  const modalItems = [
    {
      text: `${t('AssessmentHistoryPage.actionModal.showServices')}`,
      action: 'showServices',
      isVisible: true,
    },
    {
      text: `${t('AssessmentHistoryPage.actionModal.changeText')}`,
      action: 'changeAnswers',
      isVisible: element.rights?.canUpdate,
    },
    {
      text: `${t('AssessmentHistoryPage.actionModal.reassessText')}`,
      action: 'reassess',
      isVisible: element.rights?.canCreate,
    },
    {
      text: `${t('AssessmentHistoryPage.actionModal.showSummary')}`,
      action: 'showSummary',
      isVisible: assessmentsType !== 'initialAssessments',
    },
    {
      text: `${t('AssessmentHistoryPage.actionModal.changeStopDateText')}`,
      action: 'changeStopDate',
      isVisible: element.useStartEndDate && assessmentsType !== 'initialAssessments',
    },
    {
      text: `${t('AssessmentDashboardPage.startedAssessment.continueText')}`,
      action: 'continue',
      isVisible: assessmentsType === 'initialAssessments',
    },
    {
      text: `${t('AssessmentHistoryPage.actionModal.goToPerson')}`,
      action: 'goToPerson',
      isVisible:
        element.forPerson?.id != null &&
        location.pathname !== '/assessments/assessment-history/person' &&
        user.hasAccessToAssessments,
    },
    {
      text: `${t('AssessmentHistoryPage.actionModal.deleteAssessment')}`,
      action: 'deleteIncomplete',
      isVisible: assessmentsType === 'initialAssessments',
    },
    {
      text: `${t('AssessmentHistoryPage.actionModal.deleteAssessment')}`,
      action: 'deleteResult',
      isVisible: element.rights?.canDelete && assessmentsType !== 'initialAssessments',
    },
  ];

  return (
    <>
      {isVisible && (
        <div
          ref={wrapperRef}
          className="w-full h-full flex flex-col rounded-lg border relative z-10 shadow-md history-options-modal"
        >
          {data
            ? data.map(
                (item, idx) =>
                  item.isVisible && (
                    <p
                      key={idx}
                      onClick={() => handleClick(item.action, element)}
                      className={`py-1 px-4 cursor-pointer item `}
                    >
                      {item?.text}
                    </p>
                  ),
              )
            : modalItems?.map(
                (item, idx) =>
                  item.isVisible && (
                    <p
                      key={idx}
                      onClick={element ? () => handleClick(item.action, element) : () => handleClick(item.action)}
                      className={`py-1 px-4 cursor-pointer item 
                ${item.style ? item.style : ''}`}
                    >
                      {item?.text}
                    </p>
                  ),
              )}
        </div>
      )}
    </>
  );
};

AssessmentHistoryOptionsModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  assessmentsType: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  onOutsideClick: PropTypes.func.isRequired,
  element: PropTypes.object.isRequired,
  data: PropTypes.any,
};

export default AssessmentHistoryOptionsModal;
