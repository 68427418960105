const tableElements = [
  {
    id: 1,
    name: 'ICF-KOD',
    extraClass: 'w-full text-left',
  },
  {
    id: 2,
    name: 'NAMN',
    extraClass: 'w-full text-left mr-10',
  },
];

export default tableElements;
