import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { format } from 'date-fns';

import { graphFilters, graphPeriodFilter } from 'state/KubenDashboard/graphFilters';
import { graphDefinitions, graphDrillDownData } from 'state/KubenDashboard/graphs';
import { currentUserRegion } from 'state/global/currentUser';

import useMutateFetchGraphDrillDown from 'hooks/api/graph/useMutateFetchGraphDrillDown';
import UIErrorMessage from 'components/global/UICommon/UIErrorMessage';
import GraphDetailUserListBox from 'components/KubenDashboard/GraphDetailPage/GraphDetailUserListBox';
import DashboardChartWrapper from 'components/KubenDashboard/Charts/DashboardChartWrapper';

const GraphDetailPage = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const params = useParams();
  const [page, setPage] = useState(1);
  const [isSorted, setSorted] = useState(null);
  const [drillDownQueryString, setDrillDownSearchQuery] = useState(null);
  const chosenGraphRegion = useRecoilValue(currentUserRegion);
  const chosenGraphFilters = useRecoilValue(graphFilters);
  const chosenGraphPeriod = useRecoilValue(graphPeriodFilter);
  const allGraphDefinitions = useRecoilValue(graphDefinitions);
  const resetDrillDownData = useResetRecoilState(graphDrillDownData);
  const { data, isLoading, isError, mutate } = useMutateFetchGraphDrillDown(
    params.chartId,
    page,
    chosenGraphRegion?.id,
    chosenGraphFilters.regionNodes,
    chosenGraphFilters.businessTypes,
    format(chosenGraphPeriod.startDate, 'yyyy-MM-dd'),
    format(chosenGraphPeriod.endDate, 'yyyy-MM-dd'),
    isSorted,
    Number.parseInt(searchParams.get('drillDownTitleKey') || 0),
    Number.parseInt(searchParams.get('drillDownDataKey') || 0),
    drillDownQueryString,
  );

  const onMutate = () => {
    if (chosenGraphRegion?.id != null) {
      mutate();
    }
  };

  useEffect(() => {
    setPage(1);

    if (searchParams.size == 0) {
      resetDrillDownData();
    }
  }, [chosenGraphRegion, params.chartId]);

  useEffect(() => {
    onMutate();
  }, [
    chosenGraphRegion,
    chosenGraphFilters,
    chosenGraphPeriod,
    page,
    params.chartId,
    isSorted,
    searchParams.get('drillDownTitleKey'),
    searchParams.get('drillDownDataKey'),
  ]);

  return (
    <div className="w-full pt-14 h-full">
      {isError ? (
        <UIErrorMessage />
      ) : (
        <div className="space-y-5 pb-7">
          <div className="h-[40vh]">
            <DashboardChartWrapper
              graphDefinition={allGraphDefinitions.find((g) => g.graphId == params.chartId)}
              chartId={params.chartId}
              isEditLayout={false}
              chosenGraphRegion={chosenGraphRegion}
              chosenGraphFilters={chosenGraphFilters}
              chosenGraphPeriod={chosenGraphPeriod}
            />
          </div>
          <GraphDetailUserListBox
            tableData={data}
            page={page}
            setPage={setPage}
            isSorted={isSorted}
            setSorted={setSorted}
            isLoading={isLoading}
            search={drillDownQueryString}
            setSearch={setDrillDownSearchQuery}
            onSearch={onMutate}
          />
        </div>
      )}
    </div>
  );
};

export default GraphDetailPage;
