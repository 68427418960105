import query from '../useQueryWrapper';
import actions from 'api/businessType';

const useFetchIcfCode = (id) => {
  return query.useQueryWrapper(
    'icfCode',
    async () => {
      const icfCode = await actions.fetchIcfCode(id);
      return icfCode;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchIcfCode;
