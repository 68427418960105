import mutation from '../useQueryWrapper';
import actions from 'api/businessType';

const useEditIcfCode = (data) => {
  return mutation.useMutationWrapper(
    async () => {
      const editIcfCode = await actions.editIcfCode(data);
      return editIcfCode;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );
};

export default useEditIcfCode;
