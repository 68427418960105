import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import useFetchTemplates from 'hooks/api/assessment/useFetchTemplates';
import useFetchAllTemplateUnits from 'hooks/api/assessment/useFetchAllTemplateUnits';
import useFetchAssessmentResultSearch from 'hooks/api/assessmentResult/useFetchAssessmentResultSearch';

import { templates as templatesRecoil } from 'state/global/templates';
import {
  currentSearchPage as currentSearchPageRecoil,
  searchPageSize as searchPageSizeRecoil,
  unitsForSearch as unitsForSearchRecoil,
  userSearchChoices as userSearchChoicesRecoil,
} from 'state/KubenAssessments/searchPage/searchPage';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';

import SearchPageCriteriaElement from 'components/KubenAssessments/SearchPage/SearchPageCriteriaElement';
import SearchPageActionButtons from 'components/KubenAssessments/SearchPage/SearchPageActionButtons';
import SearchPageTextInput from 'components/KubenAssessments/SearchPage/SearchPageTextInput';

const SearchPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  // user chooses
  const [userSearchChoices, setUserSearchChoices] = useRecoilState(userSearchChoicesRecoil);

  // data for dropdowns
  const templatesList = useRecoilValue(templatesRecoil);
  const unitsList = useRecoilValue(unitsForSearchRecoil);

  // reset choosen states
  const resetUnitsList = useResetRecoilState(unitsForSearchRecoil);
  const resetUserSearchChoices = useResetRecoilState(userSearchChoicesRecoil);

  // pagination mechanism
  const currentPage = useRecoilValue(currentSearchPageRecoil);
  const pageSize = useRecoilValue(searchPageSizeRecoil);

  // fetching data
  const {
    isLoading: isTemplatesLoading,
    isError: isTemplatesError,
    refetch: refetchTemplates,
  } = useFetchTemplates(false);
  const {
    isLoading: isUnitsLoading,
    isError: isUnitsError,
    mutate: mutateFetchUnits,
  } = useFetchAllTemplateUnits(userSearchChoices.assessmentTemplateId);
  const { isLoading: isAssessmentsLoading, mutate: mutateFetchAssessments } = useFetchAssessmentResultSearch(
    userSearchChoices,
    currentPage,
    pageSize,
  );

  useEffect(() => {
    if (!templatesList?.length) {
      refetchTemplates();
    }
  }, [templatesList]);

  // resetting state on init
  useEffect(() => {
    resetUnitsList();
  }, []);

  // reset chosen unit while no chosen template
  useEffect(() => {
    if (userSearchChoices.assessmentTemplateId === null) {
      setUserSearchChoices({ ...userSearchChoices, organizationUnitId: null });
    }
  }, [userSearchChoices.assessmentTemplateId]);

  // fetch units after choosing templates
  useEffect(() => {
    if (userSearchChoices.assessmentTemplateId != null) {
      mutateFetchUnits();
    }
  }, [userSearchChoices.assessmentTemplateId]);

  const handleTemplateSelect = (template) => {
    setUserSearchChoices({ ...userSearchChoices, assessmentTemplateId: template?.id });
  };

  const handleUnitSelect = (unit) => {
    setUserSearchChoices({ ...userSearchChoices, organizationUnitId: unit?.id });
  };

  const handleSearchClick = () => {
    mutateFetchAssessments();
    history.push('/assessments/search/results');
  };

  const handleClearClick = () => {
    resetUserSearchChoices();
  };

  if (isTemplatesLoading || isAssessmentsLoading) {
    return <UILoadingModal />;
  }

  if (isTemplatesError || isUnitsError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <UIStaticComponentsWrapper>
      <div className="h-full w-full relative flex flex-col justify-start w-5/6 mx-auto mt-10 searchPage">
        <div className="w-full">
          <SearchPageTextInput
            searchText={userSearchChoices.text}
            setSearchText={(e) => setUserSearchChoices({ ...userSearchChoices, text: e })}
            onSubmit={handleSearchClick}
          />
        </div>
        <div className="w-full pt-8">
          <SearchPageCriteriaElement
            selectedTemplate={userSearchChoices.assessmentTemplateId}
            templatesList={templatesList}
            handleTemplateSelect={handleTemplateSelect}
            selectedUnit={userSearchChoices.organizationUnitId}
            unitsList={unitsList}
            handleUnitSelect={handleUnitSelect}
            showLatest={userSearchChoices.onlyLatest}
            handleLatestClick={() =>
              setUserSearchChoices({ ...userSearchChoices, onlyLatest: !userSearchChoices.onlyLatest })
            }
            recalculate={userSearchChoices.recalculate}
            handleRecalculateClick={() =>
              setUserSearchChoices({ ...userSearchChoices, recalculate: !userSearchChoices.recalculate })
            }
            isUnitsLoading={isUnitsLoading}
          />
        </div>
        <div className="w-full">
          <SearchPageActionButtons
            handleSearchClick={handleSearchClick}
            handleClearClick={handleClearClick}
          />
        </div>
      </div>
    </UIStaticComponentsWrapper>
  );
};

export default SearchPage;
