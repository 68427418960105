import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';

import useFetchBusinessTypesForRegion from 'hooks/api/businessType/useFetchBusinessTypesForRegion';
import useFetchRegionDetails from 'hooks/api/region/useFetchRegionDetails';
import useFetchApplicationModules from 'hooks/api/region/useFetchApplicationModules';
import useFetchUnitTypes from 'hooks/api/businessType/useFetchUnitTypes';
import useFetchNightStaffings from 'hooks/api/businessType/useFetchNightStaffings';
import useDeleteRegion from 'hooks/api/region/useDeleteRegion';
import useSaveRegion from 'hooks/api/region/useSaveRegion';
import useDeleteRegionTreeNode from 'hooks/api/region/useDeleteRegionTreeNode';
import {
  regionDetails as regionDetailsRecoil,
  selectedRegion as selectedRegionRecoil,
  modifyedRegionData as modifiedRegionDataRecoil,
  selectedRegionOriginal as selectedRegionOriginalRecoil,
  isReadOnlyState as isReadOnlyStateRecoil,
} from 'state/KubenAdmin/regions';
import getModifiedRegionDetails from 'utils/KubenAdmin/RegionsPage/getModifiedRegionDetails';
import {
  deleteTitleFromModifiedData,
  highlightElements,
  modifyDataForSortableTree,
} from 'utils/KubenAdmin/RegionsPage/modifyDataForSortableTree';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIButtonSubmit from 'components/global/UIButtons/UIButtonSubmit';
import UIButtonCheckbox from 'components/global/UIButtons/UIButtonCheckbox';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';
import UIInput from 'components/global/UICommon/UIInput';
import UIListModal from 'components/global/UIModals/UIListModal';

import UITitle from 'components/KubenAdmin/UIAdmin/UITitle';
import UIInputWrapper from 'components/KubenAdmin/UIAdmin/UIInputWrapper';
import UIFoldableCard from 'components/KubenAdmin/UIAdmin/UIFoldableCard';
import UIModalWithoutSaveData from 'components/KubenAdmin/UIAdmin/UIModalWithoutSaveData';
import UIConfirmModal from 'components/KubenAdmin/UIAdmin/UIConfirmModal';
import RegionDraggableTree from 'components/KubenAdmin/RegionsPage/RegionDraggableTree';

const RegionsPageDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [confirmModal, setConfirmModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [tmpApplicationModules, setTmpApplicationModules] = useState([]);

  const [elementToDelete, setElementToDelete] = useState({});
  const [doesHighlightElements, setDoesHighlightElements] = useState(false);
  const [isSaveAndExit, setSaveAndExit] = useState(false);
  const [elementToCompare, setElementToCompare] = useState();

  const { isLoading: isFetchDetailsLoading, isError: isFetchDetailsError } = useFetchRegionDetails(id);
  const [regionDetails, setRegionDetails] = useRecoilState(regionDetailsRecoil);
  const [modifiedRegionData, setModifiedRegionData] = useRecoilState(modifiedRegionDataRecoil);

  const {
    isLoading: isBusinessTypesForRegionLoading,
    isError: isBusinessTypesForRegionError,
    data: businessTypesForRegion,
    isSuccess: isBusinessTypesForRegionSuccess,
  } = useFetchBusinessTypesForRegion(id);
  const {
    isLoading: isApplicationModulesLoading,
    isError: isApplicationModulesError,
    isSuccess: isApplicationModulesSuccess,
    data: applicationModules,
  } = useFetchApplicationModules();
  const { isLoading: isNightStaffingsLoading, isError: isNightStaffingsError } = useFetchNightStaffings();
  const { isLoading: isUnitTypesLoading, isError: isUnitTypesError } = useFetchUnitTypes();
  const {
    isLoading: isSaveRegionLoading,
    isSuccess: isSaveRegionSuccess,
    isError: isSaveRegionError,
    mutate: mutateSaveRegion,
  } = useSaveRegion(modifiedRegionData);
  const {
    isLoading: isDeleteLoading,
    isError: isDeleteError,
    isSuccess: isDeleteSuccess,
    mutate: mutateDeleteRegion,
  } = useDeleteRegion(id);
  const {
    isLoading: isDeleteRegionTreeNodeLoading,
    isError: isDeleteRegionTreeNodeError,
    isSuccess: isDeleteRegionTreeNodeSuccess,
    mutate: mutateDeleteRegionTreeNode,
  } = useDeleteRegionTreeNode(id, elementToDelete.id);

  const setReadOnlyState = useSetRecoilState(isReadOnlyStateRecoil);
  const resetReadOnlyState = useResetRecoilState(isReadOnlyStateRecoil);

  const setSelectedRegion = useSetRecoilState(selectedRegionRecoil);
  const setSelectedRegionOriginal = useSetRecoilState(selectedRegionOriginalRecoil);
  const resetSelectedElementToEdit = useResetRecoilState(selectedRegionRecoil);
  const resetSelectedElementOriginal = useResetRecoilState(selectedRegionOriginalRecoil);

  const onOpenFormPage = async (node, action, context) => {
    await setSelectedRegion(node);
    setSelectedRegionOriginal(node);
    if (action === 'edit') {
      history.push(`/admin/regions/${id}/edit`);
    } else if (context === 'organizationUnit') {
      setReadOnlyState(true);
      history.push(`/admin/regions/${id}/add`);
    } else {
      history.push(`/admin/regions/${id}/add`);
    }
  };

  const onOpenConfirmModal = (node) => {
    setElementToDelete(node);
    setConfirmModal(true);
  };

  const onDelete = () => {
    if (elementToDelete.$type !== 'organizationUnit' && elementToDelete.$type !== 'unit') {
      mutateDeleteRegion(elementToDelete.id);
    } else {
      mutateDeleteRegionTreeNode();
    }
    setConfirmModal(false);
  };

  const onSaveRegion = (exitAfterSave) => {
    setRegionDetails(elementToCompare);
    mutateSaveRegion();
    setSaveAndExit(exitAfterSave);
  };

  const handleApplicationModulesChange = (newArray) => {
    setTmpApplicationModules(newArray);
    const applicationModules = newArray.map((el) => el.id);
    setModifiedRegionData({ ...modifiedRegionData, applicationModules: applicationModules });
  };

  useEffect(() => {
    if (Object.keys(modifiedRegionData).length > 0) {
      deleteTitleFromModifiedData(modifiedRegionData, setElementToCompare);
    }
  }, [modifiedRegionData]);

  useEffect(() => {
    if (isSaveRegionSuccess && isSaveAndExit) {
      history.push(`/admin/regions`);
    }
  }, [isSaveRegionSuccess]);

  useEffect(() => {
    if (isApplicationModulesSuccess && Object.keys(regionDetails).length !== 0) {
      const tmpApplicationModules = regionDetails.applicationModules.map((item) => {
        const matchingObject = applicationModules.find((obj) => obj.id === item);
        if (matchingObject) {
          return { ...matchingObject };
        }
        return;
      });
      setTmpApplicationModules(tmpApplicationModules);
    }
  }, [isApplicationModulesSuccess, regionDetails]);

  useEffect(() => {
    if (Object.keys(regionDetails).length !== 0 && Object.keys(modifiedRegionData).length === 0) {
      modifyDataForSortableTree(regionDetails, setModifiedRegionData);
    }
  }, [regionDetails]);

  useEffect(() => {
    if (
      Object.keys(modifiedRegionData).length !== 0 &&
      !doesHighlightElements &&
      isBusinessTypesForRegionSuccess &&
      businessTypesForRegion.length != 0
    ) {
      highlightElements(modifiedRegionData, setModifiedRegionData, businessTypesForRegion);
      // this check is added to not overloop on modifiedRegion data
      setDoesHighlightElements(true);
    }
  }, [modifiedRegionData]);

  useEffect(() => {
    setDoesHighlightElements(false);
    resetReadOnlyState();
    resetSelectedElementToEdit();
    resetSelectedElementOriginal();
  }, []);

  useEffect(() => {
    if (isDeleteSuccess) {
      history.push(`/admin/regions`);
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (isDeleteRegionTreeNodeSuccess) {
      getModifiedRegionDetails(elementToDelete.id, 'delete', modifiedRegionData, setModifiedRegionData);
    }
  }, [isDeleteRegionTreeNodeSuccess]);

  if (
    isFetchDetailsLoading ||
    isBusinessTypesForRegionLoading ||
    Object.keys(modifiedRegionData).length === 0 ||
    isSaveRegionLoading ||
    isDeleteLoading ||
    elementToCompare === undefined ||
    isApplicationModulesLoading ||
    isDeleteRegionTreeNodeLoading ||
    isNightStaffingsLoading ||
    isUnitTypesLoading
  ) {
    return <UILoadingModal />;
  }

  if (
    isFetchDetailsError ||
    isBusinessTypesForRegionError ||
    isSaveRegionError ||
    isDeleteError ||
    isApplicationModulesError ||
    isDeleteRegionTreeNodeError ||
    isNightStaffingsError ||
    isUnitTypesError
  ) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <UIStaticComponentsWrapper isAdmin={true}>
      <UIModalWithoutSaveData
        elementToCompare={elementToCompare}
        orginalElement={regionDetails}
      />
      <div className="w-full space-y-12">
        <UITitle
          title={`ADMINISTRATION AV REGION: ${(modifiedRegionData?.name).toUpperCase()}`}
          isButton={false}
        />
        <UIFoldableCard
          title="Egenskaper"
          defaultOpen={true}
        >
          <UIInputWrapper title="namn">
            <UIInput
              type="admin"
              disabled={true}
              value={modifiedRegionData.name}
            />
          </UIInputWrapper>
          <UIInputWrapper title="aktiv">
            <UIButtonCheckbox
              checked={!modifiedRegionData.disabled}
              setChecked={() =>
                setModifiedRegionData({ ...modifiedRegionData, disabled: !modifiedRegionData.disabled })
              }
              isDisabled={false}
              styleDesc={'text-sm md:text-xs'}
            />
          </UIInputWrapper>
        </UIFoldableCard>
        <UIFoldableCard title="Moduler">
          <div className="flex flex-col items-center">
            <UIInputWrapper title="valda">
              <div>
                {tmpApplicationModules.map((el, idx) => (
                  <div key={idx}>{el.value}</div>
                ))}
              </div>
            </UIInputWrapper>
            <UIButtonSubmit
              additionalPadding="mt-6"
              name="Välj moduler"
              assessments={true}
              isIcon={false}
              fixedWidth={false}
              onSubmit={() => setOpenModal(true)}
            />
            {openModal && (
              <UIListModal
                onClose={() => setOpenModal(false)}
                isOpen={openModal}
                data={applicationModules}
                choosenElements={tmpApplicationModules}
                handleClick={handleApplicationModulesChange}
                modalTitle="Moduler"
                type="report"
                isMultiple={true}
                isError={isApplicationModulesError}
                isSearchBar={false}
              />
            )}
          </div>
        </UIFoldableCard>
        <UIFoldableCard
          title="Organisationsenheter"
          defaultOpen={true}
        >
          {modifiedRegionData.rights.canUpdate && (
            <UIButtonSubmit
              name="Nytt verksamhetsområde"
              onSubmit={() => onOpenFormPage({}, 'add', 'organizationUnit')}
              variant="mx-auto"
              assessments={true}
              fixedWidth={false}
              isIcon={false}
            />
          )}
          <div className="grid flex-grow w-full border rounded my-4 shadow py-4 px-2 h-full min-h-[70vh]">
            <RegionDraggableTree
              array={modifiedRegionData}
              setArray={setModifiedRegionData}
              onDelete={onOpenConfirmModal}
              onOpenFormPage={onOpenFormPage}
            />
          </div>
        </UIFoldableCard>
        <div className="flex">
          <div className="space-x-5 mx-auto flex">
            <UIButtonSubmit
              name="Spara"
              onSubmit={() => onSaveRegion(false)}
              assessments={true}
              isIcon={false}
            />
            <UIButtonSubmit
              name="Spara och stäng"
              onSubmit={() => onSaveRegion(true)}
              assessments={true}
              isIcon={false}
            />
            {modifiedRegionData.rights.canDelete && (
              <UIButtonSubmit
                name="Radera regionen"
                onSubmit={() => onOpenConfirmModal(modifiedRegionData)}
                assessments={true}
                isIcon={false}
              />
            )}
          </div>
        </div>
      </div>
      {confirmModal && (
        <UIConfirmModal
          title={`Är du säker på att du vill radera '${elementToDelete?.name}'?`}
          onCancel={() => setConfirmModal(false)}
          onConfirm={() => onDelete()}
        />
      )}
    </UIStaticComponentsWrapper>
  );
};

export default RegionsPageDetails;
