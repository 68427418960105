import query from '../useQueryWrapper';
import actions from 'api/businessType';

const useFetchIcfCodes = () => {
  return query.useQueryWrapper(
    'fetchIcfCodes',
    async () => {
      const icfCodes = await actions.fetchIcfCodes();
      return icfCodes;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchIcfCodes;
