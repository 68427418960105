import mutation from '../useQueryWrapper';
import actions from 'api/businessType';

const useDeleteIcfCode = (id) => {
  return mutation.useMutationWrapper(
    async () => {
      await actions.deleteIcfCode(id);
    },
    { retry: false },
  );
};

export default useDeleteIcfCode;
