import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';

import formatDate from 'utils/global/formatDate';
import { currentUser } from 'state/global/currentUser';
import { chosenTemplate as chosenTemplateRecoil } from 'state/global/templates';
import { selectedPerson as selectedPersonRecoil } from 'state/KubenAssessments/saved/savedSelectedPerson';
import { sortTypeAssessmentHistory } from 'state/KubenAssessments/assessmentHistory/sortTypeAssessmentHistory';
import { answersAssessment } from 'state/KubenAssessments/assessments/answersAssessment';
import { chosenRegion as chosenRegionRecoil } from 'state/KubenAssessments/choosingTemplate/region';
import {
  assessmentHistoryFiltered as assessmentHistoryFilteredRecoil,
  assessmentHistory as assessmentHistoryRecoil,
} from 'state/KubenAssessments/assessmentHistory/assessmentHistory';
// eslint-disable-next-line max-len
import { chosenAssessmentAction as chosenAssessmentActionRecoil } from 'state/KubenAssessments/chosenAssessmentAction/chosenAssessmentAction';
import { currentQuestionState } from 'state/KubenAssessments/questions/currentQuestions';
import { latestAnsweredAssessment } from 'state/KubenAssessments/questions/latestAnsweredAssessment';

import useDeleteAssessment from 'hooks/api/assessmentResult/useDeleteAssessment';
import useFetchAllIncompleteAssessments from 'hooks/api/assessmentResult/useFetchAllIncompleteAssessments';
import useSaveAssessmentEndDate from 'hooks/api/assessmentResult/useSaveAssessmentEndDate';
import useFetchTemplateAssessment from 'hooks/api/assessment/useFetchTemplateAssessment';
import useFetchCompletedAssessment from 'hooks/api/assessmentResult/useFetchCompletedAssessment';
import useFetchPersonAssessmentHistory from 'hooks/api/assessmentResult/useFetchPersonAssessmentHistory';
import useFetchAllHistoryAssessment from 'hooks/api/assessmentResult/useFetchAllHistoryAssessment';
import useFetchAllHistoryAssessmentForManager from 'hooks/api/assessmentResult/useFetchAllHistoryAssessmentForManager';
import useDeleteAllIncompleteAssessments from 'hooks/api/assessmentResult/useDeleteAllIncompleteAssessments';
// eslint-disable-next-line max-len
import useCreateReassessmentFromCompletedAssessment from 'hooks/api/assessmentResult/useCreateReassessmentFromCompletedAssessment';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UITablePagination from 'components/global/UICommon/UITablePagination';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';
import UIWarningModal from 'components/global/UIModals/UIWarningModal';
import UITableResultsDropdown from 'components/global/UICommon/UITableResultsDropdown';
import UIConfirmModal from 'components/KubenAdmin/UIAdmin/UIConfirmModal';

import AssessmentHistoryHeader from 'components/KubenAssessments/AssessmentHistoryPage/AssessmentHistoryHeader';
import AssessmentHistoryTable from 'components/KubenAssessments/AssessmentHistoryPage/AssessmentHistoryTable';
import AssessmentHistoryDateModal from 'components/KubenAssessments/AssessmentHistoryPage/AssessmentHistoryDateModal';

const AssessmentHistoryPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isInit, setIsInit] = useState(true);
  const [assessmentsType, setAssessmentsType] = useState('initial');
  const [isRedirectLoading, setIsRedirectLoading] = useState(false);

  // setting data for continue assessment
  const setChosenAssesmentAction = useSetRecoilState(chosenAssessmentActionRecoil);
  const setAnswersAssessment = useSetRecoilState(answersAssessment);
  const setSelectedPerson = useSetRecoilState(selectedPersonRecoil);
  const [chosenRegion, setChosenRegion] = useRecoilState(chosenRegionRecoil);
  const [chosenTemplate, setChosenTemplate] = useRecoilState(chosenTemplateRecoil);

  // resetting data
  const resetChosenRegion = useResetRecoilState(chosenRegionRecoil);
  const resetChosenTemplate = useResetRecoilState(chosenTemplateRecoil);
  const resetChosenAssesmentAction = useResetRecoilState(chosenAssessmentActionRecoil);
  const resetAnswersAssessment = useResetRecoilState(answersAssessment);
  const resetLatestAnsweredAssessment = useResetRecoilState(latestAnsweredAssessment);
  const resetCurrentQuestion = useResetRecoilState(currentQuestionState);

  // pagination mechanism
  const [dataSize, setDataSize] = useState(8);
  const [page, setPage] = useState(1);
  const user = useRecoilValue(currentUser);
  const selectedPerson = useRecoilValue(selectedPersonRecoil);
  const assessmentHistory = useRecoilValue(assessmentHistoryRecoil);
  const assessmentHistoryFiltered = useRecoilValue(assessmentHistoryFilteredRecoil);
  const {
    isLoading: isAssessmentHistoryLoading,
    isError: isAssessmentHistoryError,
    mutate: mutateFetchAssessmentHistory,
  } = useFetchPersonAssessmentHistory(
    selectedPerson?.id ? selectedPerson?.id : user?.id,
    selectedPerson?.id ? 'person' : 'user',
    dataSize,
    page,
  );

  // sort mechanism
  const resetSorting = useResetRecoilState(sortTypeAssessmentHistory);
  const [sorting, setSorting] = useRecoilState(sortTypeAssessmentHistory);

  // delete all incompleted assessments mechanism
  const [confirmModal, setConfirmModal] = useState(false);
  const {
    isLoading: isDeletingAllIncompleteLoading,
    isError: isDeletingAllIncompleteError,
    mutate: mutateDeleteAllIncomplete,
    isSuccess: isDeleteAllIncompleteSuccess,
  } = useDeleteAllIncompleteAssessments();

  // date modal - set endDate properties
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [assessmentData, setAssessmentData] = useState({});
  const [assessmentEndDateData, setAssessmentEndDateData] = useState('');

  // warning modal mechanism
  const [openWarningModal, setOpenWarningModal] = useState();
  const [elementToDelete, setElementToDelete] = useState();
  const [elementTypeToDelete, setElementTypeToDelete] = useState();

  // fetching data from api
  const {
    isLoading: isAssessmentResultLoading,
    isError: isAssessmentResultError,
    isSuccess: isAssessmentResultSuccess,
    mutate: mutateAssessmentResult,
  } = useFetchCompletedAssessment();
  const {
    isLoading: isCreateReassessmentFromCompletedAssessmentLoading,
    isError: isCreateReassessmentFromCompletedAssessmentError,
    isSuccess: isCreateReassessmentFromCompletedAssessmentSuccess,
    mutate: mutateCreateReassessmentFromCompletedAssessment,
  } = useCreateReassessmentFromCompletedAssessment();
  const {
    isLoading: isTemplateAssessmentLoading,
    isError: isTemplateAssessmentError,
    isSuccess: isTemplateAssessmentSuccess,
    mutate: mutateTemplateAssessment,
  } = useFetchTemplateAssessment(chosenTemplate?.id, chosenRegion?.id);
  const {
    isLoading: isSetEndDateLoading,
    isError: isSetEndDateError,
    mutate: mutateSetEndDate,
    isSuccess: isSetEndDateSuccess,
  } = useSaveAssessmentEndDate(assessmentEndDateData);
  const {
    isLoading: isDeletingAssessmentLoading,
    isError: isDeletingAssessmentError,
    isSuccess: isDeleteAssessmentSuccess,
    mutate: mutateDeleteAssessment,
  } = useDeleteAssessment(elementToDelete, elementTypeToDelete);
  const {
    isLoading: isAllIncompleteAssessmentsLoading,
    isError: isAllIncompleteAssessmentsError,
    mutate: mutateAllIncompleteAssessments,
  } = useFetchAllIncompleteAssessments(page, dataSize);
  const {
    isLoading: isAllHistoryAssessmentsLoading,
    isError: isAllHistoryAssessmentsError,
    mutate: mutateFetchAllHistoryAssessments,
  } = useFetchAllHistoryAssessment(page, dataSize);
  const {
    isLoading: isAllHistoryAssessmentsForManagerLoading,
    isError: isAllHistoryAssessmentsForManagerError,
    mutate: mutateFetchAllHistoryAssessmentsForManager,
  } = useFetchAllHistoryAssessmentForManager(page, dataSize);

  // fetching assessments
  useEffect(() => {
    fetchAssessments();
  }, [page, history.location.pathname, dataSize]);

  useEffect(() => {
    if (assessmentEndDateData) {
      mutateSetEndDate();
    }
  }, [assessmentEndDateData]);

  // fetch proper assessments
  const fetchAssessments = () => {
    if (history.location.pathname === '/assessments/assessment-history/person') {
      mutateFetchAssessmentHistory();
      setAssessmentsType('personAssessments');
    }
    if (history.location.pathname === '/assessments/assessment-history/initial') {
      mutateAllIncompleteAssessments();
      setAssessmentsType('initialAssessments');
    }
    if (history.location.pathname === '/assessments/assessment-history/completed') {
      mutateFetchAllHistoryAssessments();
      setAssessmentsType('completedAssessments');
    }
    if (history.location.pathname === '/assessments/assessment-history/all-completed') {
      mutateFetchAllHistoryAssessmentsForManager();
      setAssessmentsType('completedAllAssessments');
    }
  };

  // mechanism to change display value size
  const changeDisplayValue = (value) => {
    setDataSize(value);
  };

  // refetch assessments after deleting
  useEffect(() => {
    if (isDeleteAssessmentSuccess || isDeleteAllIncompleteSuccess) fetchAssessments();
  }, [isDeleteAssessmentSuccess, isDeleteAllIncompleteSuccess]);

  // fetch and show updated assessments
  useEffect(() => {
    if (isSetEndDateSuccess) {
      if (assessmentsType === 'personAssessments') {
        mutateFetchAssessmentHistory();
      } else if (assessmentsType === 'completedAssessments') {
        mutateFetchAllHistoryAssessments();
      } else if (assessmentsType === 'completedAllAssessments') {
        mutateFetchAllHistoryAssessmentsForManager();
      }
    }
  }, [isSetEndDateSuccess]);

  useEffect(() => {
    if (isInit) {
      setIsInit(false);
      resetCurrentQuestion();
      resetSorting();
      resetChosenRegion();
      resetChosenTemplate();
      resetChosenAssesmentAction();
      resetAnswersAssessment();
      resetLatestAnsweredAssessment();
      return;
    }
    if (chosenRegion?.id && chosenTemplate?.id) {
      mutateTemplateAssessment();
    }
  }, [chosenRegion, chosenTemplate]);

  // redirect user to modify completed assessment
  useEffect(() => {
    if (isAssessmentResultSuccess || isCreateReassessmentFromCompletedAssessmentSuccess) {
      history.push('/assessments/questions');
    }
  }, [isAssessmentResultSuccess, isCreateReassessmentFromCompletedAssessmentSuccess]);

  // redirect user to summary page
  useEffect(() => {
    if (isTemplateAssessmentSuccess) {
      history.push('/assessments/questions-summary');
    }
  }, [isTemplateAssessmentSuccess]);

  const handleDateModalClick = (action, assessmentId, assessmentEndDate, reasonEndDay) => {
    if (action === 'closeModal') setIsDateModalOpen(false);
    if (action === 'setEndDate') {
      setAssessmentEndDateData({
        id: assessmentId,
        date: assessmentEndDate !== null ? formatDate(assessmentEndDate, 'yyyy-MM-dd') : null,
        reason: reasonEndDay,
      });
      setIsDateModalOpen(false);
    }
  };

  const handleDelateModalClick = () => {
    mutateDeleteAssessment();
    setOpenWarningModal(false);
  };

  // delete all assessments mechanism
  const onDeleteAllIncompletedAssessments = () => {
    setConfirmModal(true);
  };

  const onCancelDeleteAllButton = () => {
    setConfirmModal(false);
  };

  const onConfirmDeleteAllButton = () => {
    mutateDeleteAllIncomplete();
    setConfirmModal(false);
  };

  const handleOptionsClick = (item, action) => {
    const id = item?.id;
    switch (action) {
      case 'reassess':
        mutateCreateReassessmentFromCompletedAssessment({ id });
        return;
      case 'changeAnswers':
        mutateAssessmentResult({ id });
        return;
      case 'showSummary':
        setIsRedirectLoading(true);
        // save all necessary data for summary page
        setChosenAssesmentAction('summaryReadonly');
        setAnswersAssessment(item);
        setChosenRegion(item?.region);
        setChosenTemplate(item?.assessment);
        return;
      case 'showServices':
        history.push(`/assessments/services/${item?.id}`);
        return;
      case 'changeStopDate':
        setAssessmentData(item);
        setIsDateModalOpen(true);
        break;
      case 'deleteIncomplete':
      case 'deleteResult':
        setOpenWarningModal(true);
        setElementToDelete(item?.id);
        setElementTypeToDelete(action);
        break;
      case 'continue':
        setChosenAssesmentAction('existingAssessment');
        setIsRedirectLoading(true);
        setChosenRegion(item.region);
        setChosenTemplate(item.assessment);
        setAnswersAssessment(item);
        setSelectedPerson(item.forPerson != null && item.forPerson);
        setTimeout(() => {
          history.push('/assessments/questions');
        }, 200);
        break;
      case 'goToPerson':
        setSelectedPerson(item.forPerson);
        history.push('/assessments/person/' + item.forPerson?.id);
        break;
    }
  };

  if (
    isAssessmentResultLoading ||
    isCreateReassessmentFromCompletedAssessmentLoading ||
    isAllIncompleteAssessmentsLoading ||
    isAllHistoryAssessmentsLoading ||
    isTemplateAssessmentLoading ||
    isSetEndDateLoading ||
    isAssessmentHistoryLoading ||
    isDeletingAssessmentLoading ||
    isRedirectLoading ||
    isDeletingAllIncompleteLoading ||
    isAllHistoryAssessmentsForManagerLoading
  ) {
    return <UILoadingModal />;
  }

  if (
    isAssessmentResultError ||
    isCreateReassessmentFromCompletedAssessmentError ||
    isTemplateAssessmentError ||
    isSetEndDateError ||
    isAssessmentHistoryError ||
    isAllIncompleteAssessmentsError ||
    isAllHistoryAssessmentsError ||
    isDeletingAssessmentError ||
    isDeletingAllIncompleteError ||
    isAllHistoryAssessmentsForManagerError
  ) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <UIStaticComponentsWrapper isTopMargin={false}>
      <UIWarningModal
        isOpen={openWarningModal}
        setOpen={setOpenWarningModal}
        onClick={handleDelateModalClick}
        modalTitle={t('UIButtons.edit')}
        modalMessage={t('UIModals.editModalMessage')}
        confirmButtonName={t('UIButtons.edit')}
      />
      <div className="flex flex-col history-container h-full searchPage w-full mx-auto">
        <div className="mb-6 w-full h-full searchPage">
          <AssessmentHistoryHeader
            sortType={sorting}
            handleClick={(sortType) => setSorting(sortType)}
          />
          <div className="mt-6 assesmentHistoryTable">
            <AssessmentHistoryTable
              isDeleteAllButton={assessmentsType === 'initialAssessments' ? true : false}
              deleteButtonFunction={onDeleteAllIncompletedAssessments}
              visibleElements={dataSize}
              assessmentHistory={assessmentHistoryFiltered}
              handleClick={handleOptionsClick}
              assessmentsType={assessmentsType}
            />
          </div>
          <div className="mt-6 pb-36">
            {(assessmentHistory?.moreResultsAvailable || page !== 1) && (
              <div className="flex">
                <UITablePagination
                  page={page}
                  setPage={setPage}
                  hasNextPage={assessmentHistory?.moreResultsAvailable}
                  data={assessmentHistoryFiltered}
                />
                <UITableResultsDropdown
                  dataSize={dataSize}
                  onChange={changeDisplayValue}
                />
              </div>
            )}
          </div>
        </div>
        {isDateModalOpen && (
          <div className="w-[560px] h-full">
            <AssessmentHistoryDateModal
              isOpen={isDateModalOpen}
              assessmentData={assessmentData}
              handleClick={(action, id, date, reason) => handleDateModalClick(action, id, date, reason)}
            />
          </div>
        )}
      </div>
      {confirmModal && (
        <UIConfirmModal
          title="Är du säker på att du vill radera alla påbörjade bedömningar?"
          onCancel={onCancelDeleteAllButton}
          onConfirm={onConfirmDeleteAllButton}
          onCancelButtonName="Nej"
          onConfirmButtonName="Ja"
          widerModal={true}
        />
      )}
    </UIStaticComponentsWrapper>
  );
};

export default AssessmentHistoryPage;
