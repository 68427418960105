import React, { useState } from 'react';
import { useRecoilState } from 'recoil';

import getUUID from 'utils/KubenAdmin/getUUID';
import { templateFormState } from 'state/KubenAdmin/templates';

import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';
import UIAdvancedTableElement from '../UITable/UIAdvancedTableElement';
import UIAdvancedTable from '../UITable/UIAdvancedTable';
import UIAddElementModal from '../UIAdmin/UIAddElementModal';

import TemplateCalculationResultPropertiesForm from './TemplateCalculationResultPropertiesForm';

const TemplateCalculationResultList = () => {
  const [template, setTemplate] = useRecoilState(templateFormState);
  const [isAddCalculationResult, setAddCalculationResult] = useState(false);

  const alterCalculationResults = (name, deleteIndex) => {
    const tmpTemplate = { ...template };
    const tmpCalculationResults = [...tmpTemplate.calculationResults];

    if (deleteIndex !== undefined) {
      tmpCalculationResults.splice(deleteIndex, 1);
    } else {
      tmpCalculationResults.push({
        id: getUUID(),
        name: name,
        summaryText: null,
        pointSource: null,
        formula: null,
        adminOnly: false,
        businessTypeId: template.businessTypeId,
        resourceGroupId: 'Internal',
        staffingIsLevelBased: false,
        generateResourceNeeds: false,
        staffingByUnitTypeAndLevelReferences: null,
        levelsOverwrite: null,
      });
    }
    setTemplate({ ...template, calculationResults: tmpCalculationResults });
  };

  return (
    <div>
      {isAddCalculationResult && (
        <UIAddElementModal
          modalTitle="Lägg till beräkningsresultat"
          inputPlaceholder="Namn"
          onSave={(text) => alterCalculationResults(text)}
          onClose={() => setAddCalculationResult(false)}
          isOpen={true}
        />
      )}
      <UIAdvancedTable>
        {template.calculationResults.map((el, idx) => (
          <UIAdvancedTableElement
            key={`key_${el.id}`}
            id={`id_${el.id}`}
            index={idx}
            title={el.name}
            onDelete={() => alterCalculationResults(null, idx)}
            canMove={false}
          >
            <TemplateCalculationResultPropertiesForm index={idx} />
          </UIAdvancedTableElement>
        ))}
      </UIAdvancedTable>
      <div className="py-4 flex justify-center">
        <UIButtonSubmit
          name="Nytt beräkningsresultat"
          onSubmit={() => setAddCalculationResult(true)}
          assessments={true}
          isIcon={false}
          fixedWidth={false}
        />
      </div>
    </div>
  );
};

export default TemplateCalculationResultList;
