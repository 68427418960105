import React from 'react';
import PropTypes from 'prop-types';

const ChooseAssessmentChoiceCard = ({ url, icon, action, title, description, isDisabled, handleClick }) => {
  return (
    <button
      disabled={isDisabled}
      onClick={() => handleClick(action, url)}
      className={`flex flex-col relative w-full h-full
        transition-colors ease-out duration-300 border border-tertiary bg-white rounded-2xl
        space-y-3 text-left p-6 ${
          isDisabled ? 'cursor-not-allowed opacity-[0.65] disabled' : 'cursor-pointer choice-card'
        }`}
    >
      <div className="p-2 rounded-lg background-primary w-fit text-white text-3xl mb-2">{icon}</div>
      <h2 className="font-normal text-lg lg:text-xl xl:text-2xl">{title}</h2>
      <p className="font-normal text-base">{description}</p>
    </button>
  );
};

ChooseAssessmentChoiceCard.propTypes = {
  url: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
};

export default ChooseAssessmentChoiceCard;
